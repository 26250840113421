import * as baseStore from "./base2.store";
// import { getPastDateInUTC } from "../../utils/date-utils.js";
import * as purchasedItemModel from "../../views/purchased-items/purchased-item-model.js";
import config from "../../../config.js";

const initialState = () => {
  return {
    ...baseStore.state,
    list: {
      allowInsert: false,
      amazonLinkField: "asin",
      amazonUrlField: "amazonUrl",
      currentPage: 1,
      documentTitle: "g3tools - Purchased Items Receive",
      field: [],
      imageField: "imageUrl",
      keyField: "",
      listName: "Purchased Item Receive - Pending",
      orderClause: "",
      pageSize: 50,
      role: "PurchQp",
      routeName: "PurchasedItemReceivePending",
      searchText: "",
      selectedItems: 0,
      selectMode: "single", // multi, single, range
      selectRowShowsDetail: true,
      showAdd: true,
      showDelete: false,
      showOtherActions: false,
      sortBy: "ts",
      sortOrder: "DESC",
      totalRows: 0,
    },
    entityDescription: "Purchased Item Receive",
    subNavTitle: "Purchased Item Receive",
    sidebarHidden: false,
    filters: purchasedItemModel.getFilters(config.SCOPES.CLIENT),
  };
};

// // Instantiate base store, passing in root endpoint and state
// const base = new BaseStore("purch-item", useState);
// let baseState = { ...base.state };

// State object instantiated so we can easily reset using initialState
// const initialState = () => {
//   return {
//     // State from base store with overridden values
//     ...baseState,
//   };
// };

const state = initialState();

// Getter functions
const getters = {
  // Standard getters from base store added here
  ...baseStore.getters,
  currentItem: (state) => state.currentItem,
  isItemLoaded: (state) => {
    return !!state.currentItem;
  },
  list: (state) => state.list,
  filters: (state) => state.filters,
  items: (state) => state.items,
  comments: (state) => state.comments,
  attachments: (state) => state.attachments,
};

// Actions
const actions = {
  ...baseStore.actions,
  async initializeStore({ commit, rootState }) {
    // Set fields and filters based on scope
    const useScope = rootState.Session.scope;
    const scopedFields = purchasedItemModel.getFields(useScope);
    commit("SET_FIELDS", scopedFields);
    const scopedFilters = purchasedItemModel.getFilters(useScope);
    commit("SET_FILTERS", scopedFilters);
  },
  async clearFilters({ state, dispatch }, { fetchOnStart }) {
    // Set filters based on route
    await dispatch("baseClearFilters", {
      endpoint: "/purch-item",
      fetchOnStart,
      state,
    });
    await dispatch("baseFetchAll", { endpoint: "/purch-item", state });
  },
  // Override clearFilterOnly
  async clearFiltersOnly({ state, commit, dispatch }) {
    // Set filters based on route
    await dispatch("dynamicFilters");
    await baseStore.actions.baseClearFiltersOnly({ state, commit, dispatch });
  },
  dynamicFilters(context) {
    purchasedItemModel.dynamicFilters(state, context);
  },
  // Use the shared fetchItem action from base.store
  //  async fetchItem({ dispatch, commit, state }, itemId) {
  async fetchItem({ dispatch, commit, state }, itemId) {
    commit("PURCH_ITEM_RESET");
    await dispatch("baseFetchItem", {
      endpoint: "/purch-item",
      itemId,
      state,
    });
  },
  async fetchAll({ dispatch, state }) {
    await dispatch("baseFetchAll", { endpoint: "/purch-item", state });
  },

  //async itemCreate({ dispatch, state }, item) {},

  async itemSaveUnreadFlag({ dispatch }, id) {
    if (state.currentItem.id !== id) {
      throw new Error("Current item doesn't match expected item id");
    }
    const payload = {
      id: id,
      tenantId: state.currentItem.tenantId,
      clientId: state.currentItem.clientId,
    };
    await dispatch("baseItemSave", {
      endpoint: "/purch-item/unread",
      record: payload,
    });
  },
  //   Specific actions here
  async itemSave({ dispatch, commit }, record) {
    // Add receive qty to logEvent
    // commit("LOG_EVENT_SET", {
    //   key: "addReceivedQty",
    //   value: state.currentItem.addReceiveQty,
    // });
    // Combine currentItem
    commit("CURRENT_ITEM_SET_ASSIGN", record);
    const updatedRecord = await dispatch("baseItemSave", {
      endpoint: "/purch-item",
      record,
    });
    commit("CURRENT_ITEM_SET_ASSIGN", updatedRecord.data);

    // // Add fields to object
    // const item = {
    //   id: state.currentItem.id,
    //   asin: state.currentItem.asin,
    //   receivedQty: newReceiveQty,
    //   onHoldQty: state.currentItem.onHoldQty,
    //   receiveNote: state.currentItem.receiveNote,
    //   isShippedToAzn: state.currentItem.isShippedToAzn,
    //   missingQty: state.currentItem.missingQty,
    //   totalMissingCostAmt: state.currentItem.totalMissingCostAmt,
    //   deliveredNotes: state.currentItem.deliveredNotes,
    //   trackingNumbers: state.currentItem.trackingNumbers,
    //   isMissingItem: state.currentItem.isMissingItem,
    //   missingNotes: state.currentItem.missingNotes,
    //   researchStatus: state.currentItem.researchStatus,
    //   $logEvent: state.currentItem.$logEvent,
    // };
    // return await base.actions.itemSave(
    //   { state, commit, dispatch, rootState },
    //   { item }
    // );
  },
  // Dropdown filter set
  async setFilterSelectedItems(context, payload) {
    context.commit("FILTER_SELECTED_SET", payload);
  },
};

// Mutations
const mutations = {
  // Standard mutations from base store class
  ...baseStore.mutations,
  PURCH_ITEM_RESET(state) {
    state.currentItemCopy = {};
  },
  SET_FIELDS(state, data) {
    state.list.fields = data;
  },
  SET_FILTERS(state, data) {
    state.filters = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
