<template>
  <!-- Comments List -->
  <div>
    <b-list-group v-for="(comment, index) in comments" :key="index">
      <b-list-group-item class="results-item">
        <IssueCommentItem
          :namespace="namespace"
          :comment="comment"
          :user="user"
          @g3CommentDirty="commentDirty"
          @g3CommentBlurred="commentBlurred"
          @g3CommentDeleted="commentDeleted"
          class="results-item"
        />
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import { formatDate } from "../../utils/date-utils.js";
import { mapGetters } from "vuex";
import IssueCommentItem from "./IssueCommentsItem.vue"

export default {
  name: "IssueComments",
  components: {IssueCommentItem},
  props: {
    namespace: { type: String, default: "" },
    comments: Array,
    user: Object
  },
  data() {
    return {
    };
  },
  created() { 
  },
  computed: {
    ...mapGetters("Session", ["isAuthorized"]),
    noComments() {
      return !this.useComments;
    }
  },
  methods: {
    formatDate: function(rawDate) {
      return formatDate(rawDate);
    },
    commentDirty(data) {
      this.$emit("g3CommentDirty", data);
    },
    commentBlurred(data) {
      this.$emit("g3CommentBlurred", data);
    },
    commentDeleted(data) {
      this.$emit("g3CommentDeleted", data);
    },
  }
};
</script>

<style lang="css" scoped>
.results-item {
  background-color: transparent;
  padding: 0;
  border: none;
  margin-bottom: 0px;
}
.no-data {
  font-size: 0.9em;
}
</style>