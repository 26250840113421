import store from "../store";
import Vue from "vue";
import VueRouter from "vue-router";

// Import route modules
//import purchasedItemRoutes from "./purchasedItem.router.js";
//import issueRoutes from "./issue.router.js";
import amazonRoutes from "./amazon.router.js";

// Import other route components
import Login from "../views/auth/Login.vue";
import Dashboard from "../views/dashboard/Dashboard.vue";
import DashboardSlcPrep from "../views/dashboard/DashboardSlcPrep.vue";
import Admin from "../views/auth/Admin.vue";
import Profile from "../views/auth/Profile.vue";
import About from "../views/auth/About.vue";
import NotFound from "../views/navigation/PageNotFound.vue";

Vue.use(VueRouter);

// Purchased Item routes
// ***************************************************************
import PurchasedItemReceiveHome from "../views/purchased-items/purchasedItemReceive/PurchasedItemReceiveHome.vue";
//import BaseG3List from "../components/BaseG3List.vue";
import PurchasedItem from "../views/purchased-items/PurchasedItem.vue";
// Names used in routes.
const receivePending = "PurchasedItemReceivePending";
const receiveAll = "PurchasedItemReceiveAll";
const receive21d = "PurchasedItemReceive21d";
const useNamespaceP = "PurchasedItem";
const purchasedItemRoutes = [
  {
    path: "/purch-item/receive",
    //    name: "ReceiveHome",
    component: PurchasedItemReceiveHome,
    meta: { requiresAuth: true },
    children: [
      {
        // Receive - Pending
        path: "", // Default child route
        name: receivePending,
        component: BaseG3List,
        props: {
          namespace: useNamespaceP,
        },
        meta: {
          breadcrumb: [
            {
              text: "Purchased Items",
              to: { name: receivePending },
            },
            {
              text: "Receive Pending",
            },
          ],
        },
      },
      {
        // Receive - All
        path: "purch-receive--all",
        name: receiveAll,
        component: BaseG3List,
        props: { namespace: useNamespaceP },
        meta: {
          breadcrumb: [
            {
              text: "Purchased Items",
              to: { name: receiveAll },
            },
            {
              text: "Received All",
            },
          ],
        },
      },
      {
        // Received > 21 days
        path: "purch-receive--21d",
        name: receive21d,
        component: BaseG3List,
        props: { namespace: useNamespaceP },
        meta: {
          breadcrumb: [
            {
              text: "Purchased Items",
              to: { name: receive21d },
            },
            {
              text: "Received > 21 Days",
            },
          ],
        },
      },
      {
        path: ":id/detail",
        name: "PurchasedItem",
        component: PurchasedItem,
        props: true,
        meta: {
          breadcrumb: [
            {
              text: "Purchased Item",
              to: { name: "PurchasedItem" },
            },
            { text: "Purchased Item" },
          ],
        },
      },
    ],
  },
];
//                End of PurchasedItem routes
// **************************************************

// Issue route imports
import IssueHome from "../views/issue/IssueHome.vue";
import BaseG3List from "../components/BaseG3List.vue";
import IssueItem from "../views/issue/IssueItem.vue";
// Issue route constants
const issueOpen = "OpenIssues";
const myAssigned = "IssuesAssignedToMe";
const myGroupAssigned = "IssuesAssignedToMyGroup";
const closedIssues = "ClosedIssues";
const useNamespace = "Issue2";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { requiresAuth: false },
  },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "/DashboardSlcPrep",
    name: "DashboardSlcPrep",
    component: DashboardSlcPrep,
    meta: { requiresAuth: true },
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  // Combine routes
  //...issueRoutes,
  ...purchasedItemRoutes,
  ...amazonRoutes,
  // Issue routes begin
  {
    path: "/issue",
    //    name: "IssueHome",
    component: IssueHome,
    meta: { requiresAuth: true },
    children: [
      {
        // Open issues
        path: "", // Default route
        name: issueOpen,
        component: BaseG3List,
        props: { namespace: useNamespace },
        meta: {
          breadcrumb: [
            { text: "Issues", to: { name: issueOpen } },
            { text: "Processing Issues - All Open" },
          ],
        },
      },
      {
        // Issues assigned to me
        path: "issue/assigned-to-me",
        name: myAssigned,
        component: BaseG3List,
        props: { namespace: useNamespace },
        meta: {
          breadcrumb: [
            { text: "Issues", to: { name: myAssigned } },
            { text: "Processing Issues - Assigned to Me" },
          ],
        },
      },
      {
        // Issues assigned to my group (client)
        path: "issue/assigned-to-group",
        name: myGroupAssigned,
        component: BaseG3List,
        props: { namespace: useNamespace },
        meta: {
          breadcrumb: [
            { text: "Issues", to: { name: myGroupAssigned } },
            { text: "Processing Issues - Assigned to my Group" },
          ],
        },
      },
      {
        // Closed issues
        path: "issue/closed",
        name: closedIssues,
        component: BaseG3List,
        props: { namespace: useNamespace },
        meta: {
          breadcrumb: [
            { text: "Issues", to: { name: closedIssues } },
            { text: "Processing Issues - All" },
          ],
        },
      },
      {
        path: ":id/detail",
        name: "IssueItem",
        component: IssueItem,
        props: true,
        meta: {
          breadcrumb: [
            { text: "Issues", to: { name: "IssueItem" } },
            { text: "Issue Item" },
          ],
        },
      },
    ],
  },
  //  End of issue routes
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: { requiresAuth: true },
  },
  {
    path: "/about",
    name: "About",
    component: About,
    meta: { requiresAuth: true },
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  hash: false,
  base: process.env.BASE_URL,
  routes,
});

// Store route in store state.list.route
// router.beforeEach((to, from, next) => {
//   console.log("beforeEach namespace: ", to?.props?.namespace);
//   if (to?.props?.namespace && to.path !== from.path) {
//     //store.commit("PurchasedItemReceive/CURRENT_ROUTE_SET", to);
//   }
//   next();
// });

// If not authenticated, send user to login
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters["Session/isAuthenticated"]) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});
// If attempting an admin link, must be admin
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAdmin)) {
    if (store.getters["Session/isAdmin"]) {
      next();
      return;
    }
    next("/");
  } else {
    next();
  }
});


export default router;
