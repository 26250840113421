export function getFields(scope) {
  const fields = [
    {
      key: "index",
      sortable: false,
      label: "",
      class: "col-right",
    },
    // {
    //   key: "selected",
    //   sortable: false,
    //   class: "col-center",
    // },
    {
      // imageUrl
      key: "image",
      label: "Image",
      sortable: false,
      sortBy: "asin",
      class: "col-action",
    },
    {
      // key: "action" to create link to detail item
      key: "issueReason",
      label: "Issue Reason",
      sortable: true,
      sortBy: "issueReason",
      class: "col-action col-elipsis",
      formatter: (value, key, item) => {
        return item.issueReason?.length > 80
          ? `${item.issueReason.substring(0, 80)}...`
          : item.issueReason;
      },
    },
    {
      key: "id",
      label: "Id",
      sortable: true,
      class: "col-70 col-center",
    },
    {
      key: "clientName",
      label: "Client",
      sortable: true,
    },
    {
      key: "hasAttachments",
      label: "Attachments",
      class: "col-center",
      sortable: true,
    },
    {
      key: "asin",
      label: "Asin",
      sortable: true,
    },
    {
      key: "originator",
      label: "Originator",
      sortable: true,
    },
    {
      key: "typeName",
      label: "Type",
      sortable: true,
    },
    {
      key: "statusName",
      label: "Status",
      sortable: true,
      class: "col-100",
    },
    {
      // Will mark urgent with red background
      key: "priorityName",
      label: "Priority",
      sortable: true,
      class: "col-100",
    },
    {
      key: "issueQty",
      label: "Issue Qty",
      class: "col-center",
      sortable: true,
      showTotal: true,
    },
    {
      key: "assigneeFamiliarName",
      label: "Assignee",
      sortable: true,
      class: "col-100",
    },
    {
      key: "actionName",
      label: "Action",
      sortable: true,
    },
    {
      key: "createdOn",
      label: "Created",
      class: ["col-center", "col-130"],
      sortable: true,
      formatter: (value, key, item) => {
        return item.createdOn && new Date(item.createdOn).toLocaleDateString();
      },
    },
    {
      key: "ts",
      label: "Modified",
      class: ["col-center"],
      sortable: true,
      formatter: (value, key, item) => {
        return item.ts && new Date(item.ts).toLocaleDateString();
      },
    },
  ];
  // Remove clientName if client scope
  if (scope === "client") {
    return fields.filter((field) => field.key !== "clientName");
  }
  return fields;
}
/**
 * Return different set of filters to use on list depending on user scope
 * type: "select" or "action" (select control or option button control)
 * field: Key field that identifies the object and we can match with a list of options
 * label: Label to use with the select/option buttn control
 * idField: If options (or fileOptions) is an array of objects, use this field for return the id, like "statusId"
 * valueField: If options (or fileOptions) is an array of objects, use this field for matching with foreign key table, like "statusName"
 * displayField: If options (or fileOptions) is an array of objects, use this field as an alternative to display "displayName"
 * options: Array of options to be displayed on list (can be an array of strings or objects)
 * itemOptions: The options to use for item detail record as apposed to the list
 * includeInTable: Include his filter control in the list
 * value: The current value selected
 * defaultValue: What to use if needed (especially for options buttons, like status)
 * operator: Operator to use in buildng a where clause (WHERE <value> NOT IN ...)
 * multSelect: Is this options dropdown multiselect? Default is false
 * allowBulkChange: This field can be selected to change in bulk (not implemented now)
 * fetchOptions: Fetch the options for this filter from the database
 * @param {String} scope - "tenant" or "client"
 * @returns
 */
export function getFilters(scope) {
  const filters = [
    {
      type: "select",
      field: "originator",
      label: "Originator",
      options: [],
      itemOptions: [],
      includeInTable: false,
      value: null,
      operator: "=",
      multiSelect: false,
      allowBulkChange: true,
      fetchOptions: true,
    },
    {
      type: "select",
      field: "clientFacilityName",
      label: "Client Facility",
      idField: "clientFacilityId",
      nameField: "clientFacilityName",
      displayField: "clientFacilityName",
      includeInTable: false,
      options: [],
      itemOptions: [],
      defaultOperator: "=",
      defaultValue: "",
      value: null,
      operator: "=",
      multiSelect: false,
      allowBulkChange: true,
      fetchOptions: true,
    },
    {
      type: "select",
      field: "processorFacilityName",
      label: "Processor Facility",
      idField: "processorFacilityId",
      nameField: "processorFacilityName",
      displayField: "processorFacilityName",
      includeInTable: false,
      options: [],
      itemOptions: [],
      defaultOperator: "=",
      defaultValue: "",
      value: null,
      operator: "=",
      multiSelect: false,
      allowBulkChange: true,
      fetchOptions: true,
    },
    {
      type: "select",
      field: "clientName",
      label: "Client Name",
      idField: "clientId",
      nameField: "clientName",
      displayField: "clientName",
      includeInTable: true,
      options: [],
      itemOptions: [],
      defaultOperator: "=",
      defaultValue: "",
      value: null,
      operator: "=",
      multiSelect: false,
      allowBulkChange: true,
      fetchOptions: true,
    },
    {
      type: "select",
      field: "typeName",
      idField: "typeId",
      nameField: "typeName",
      displayField: "typeName",
      label: "Type",
      includeInTable: true,
      options: [],
      itemOptions: [],
      defaultOperator: "",
      defaultValue: "",
      value: "",
      operator: "=",
      multiSelect: false,
      allowBulkChange: true,
      fetchOptions: true,
    },
    {
      type: "select",
      field: "priorityName",
      label: "Priority",
      idField: "priorityId",
      nameField: "priorityName",
      displayField: "priorityName",
      includeInTable: true,
      options: [],
      itemOptions: [],
      value: null,
      operator: "=",
      multiSelect: false,
      allowBulkChange: true,
      fetchOptions: true,
    },
    {
      type: "action",
      field: "statusName",
      label: "Status",
      idField: "statusId",
      nameField: "statusName",
      displayField: "statusName",
      value: null,
      defaultValue: "Open",
      defaultOperator: "=",
      operator: "=",
      includeInTable: true,
      options: [],
      itemOptions: [],
      allowBulkChange: false,
      fetchOptions: false,
    },
    {
      type: "select",
      field: "assigneeFamiliarName",
      label: "Assignee",
      idField: "assigneeId",
      nameField: "assigneeFamiliarName",
      displayField: "displayName",
      includeInTable: true,
      defaultValue: "",
      options: [],
      itemOptions: [],
      value: null,
      operator: "=",
      multiSelect: false,
      allowBulkChange: true,
      fetchOptions: true,
    },
    {
      type: "select",
      field: "actionName",
      label: "Action",
      idField: "actionId",
      nameField: "actionName",
      displayField: "actionName",
      includeInTable: true,
      fetchOptions: true,
      options: [],
      itemOptions: [],
      value: null,
      operator: "=",
      multiSelect: false,
      allowBulkChange: false,
    },
    {
      // Used for custom where clause: My group's issues
      type: "select",
      field: "clientId",
      label: "Client Id",
      includeInTable: false,
      defaultValue: "",
      operator: "=",
      fetchOptions: false,
    },
  ];
  // Remove client filter if client
  if (scope === "client") {
    return filters.filter((filter) => filter.field !== "clientName");
  }
  return filters;
}
/**
 * Allows us to use the same store to feed the table with different result sets
 * based on route that got us here
 * @param {obj} state
 * @param {obj} context
 */
export const dynamicFilters = (state, context) => {
  // Default condition
  context.commit("FILTER_INCLUDE_IN_TABLE_VALUE_SET", {
    key: "assigneeFamiliarName",
    value: true,
  });
  context.commit("FILTER_INCLUDE_IN_TABLE_VALUE_SET", {
    key: "statusName",
    value: true,
  });
  context.commit("FILTER_DEFAULT_VALUE_SET", {
    key: "assigneeFamiliarName",
    value: null,
  });
  context.commit("FILTER_DEFAULT_VALUE_SET", {
    key: "statusName",
    value: "Open",
  });
  context.commit("LIST_SET", { key: "sortBy", value: "ts" });
  context.commit("LIST_SET", { key: "sortOrder", value: "DESC" });
  // Set filters based on route name
  switch (state?.list?.routeName) {
    case "IssuesAssignedToMe":
      // My issues
      context.commit("FILTER_INCLUDE_IN_TABLE_VALUE_SET", {
        key: "assigneeFamiliarName",
        value: false,
      });
      context.commit("FILTER_DEFAULT_VALUE_SET", {
        key: "assigneeFamiliarName",
        value: context.rootState.Session.user.familiarName,
      });
      //     commit("LIST_SET", { key: "sortBy", value: "purchasedOn" });
      //     commit("LIST_SET", { key: "sortOrder", value: "ASC" });
      break;
    case "ClosedIssues":
      // Closed issues
      context.commit("FILTER_DEFAULT_VALUE_SET", {
        key: "statusName",
        value: "Closed",
      });
      context.commit("FILTER_INCLUDE_IN_TABLE_VALUE_SET", {
        key: "statusName",
        value: false,
      });
      break;
  }
};

export const defaultIssue = (user) => {
  const record = {
    id: 2,
    tenantId: user.tenantId,
    clientId: user.clientId,
    processorId: user.processorId,
    processorFacilityId: user.processorFacilityId,
    clientFacilityId: user.clientFacilityId,
    typeId: 0,
    statusId: 1,
    priorityId: 0,
    originator: "",
    issueReason: "",
    issueQty: 0,
    actionId: 0,
    purchasedItemRecordId: 0,
    assigneeId: 0,
    unread: true,
    createdBy: user.userId,
    productId: "",
    imageUrl: "",
    lastUpdatedBy: user.userId,
  };
  return record;
};

