<template>
<div class="outer-container">
  <div class="flex-container">
      <b-breadcrumb
        :items="breadcrumbItems"
        class="m-0 p-2 pl-3 bc-container border-bottom"
      />
      <div class="title-container">
        <span class="centered-title table-title">{{ tableTitle }}</span>
      </div>
    </div>  
  </div>
</template>

<script>
export default {
  name: "BaseBreadcrumb",
  props: {
    title: {
      type: String,
      default: "Default Title"
    }
  },  data() {
    return {
      breadcrumbItems: [],
      tableTitle: "Default Title"
    };
  },
  mounted() {
    this.updateList();
  },
  watch: {
    $route() {
      this.updateList();
    }
  },
  methods: {
    routeTo(pRouteTo) {
      if (this.breadcrumbItems[pRouteTo].link)
        this.$router.push(this.breadcrumbItems[pRouteTo].link);
    },
    updateList() {
      this.breadcrumbItems = this.$route.meta.breadcrumb;
      this.tableTitle = this.$route.meta.breadcrumb[1].text;
    }
  }
};
</script>

<style lang="css" scoped>
.bc-container {
  background: transparent;
}
.full-height {
  height: 100vh; /* or 100% if it's inside another container */
}
.outer-container {
  display: flex;

  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid light gray;
}

.flex-container {
  display: flex;
  align-items: center;
  width: 100%;
}
.title-container {
  flex: 1;
  display: flex;
  justify-content: center;
  margin-right: 100px;
}
.centered-title {
  text-align: center;
}

</style>
