<template>
    <div class="m-3">
        <b-form-row>
            <h4 class="section-heading">Purchased Item Information</h4>
        </b-form-row>
        <b-form-row>
            <b-col cols="7">
                <div>
                <!-- Top-left controls -->
                <BaseFormInput
                v-for="item in topLeft"
                :key="item.id"
                :id="item.id"
                :label="item.label"
                :labelCols=4
                plaintext
                labelClass="text-muted"
                size="sm"
                readonly
                compact
                :value="item.value"
                />
                </div>
            </b-col>
            <b-col>
                <div>
                <!-- Top-right controls -->
                <BaseFormInput
                v-for="item in topRight"
                :key="item.id"
                :id="item.id"
                :label="item.label"
                labelAlign="right"
                :labelCols=5
                plaintext
                labelClass="text-muted"
                size="sm"
                readonly
                compact
                :value="item.value"
                />
                </div>
            </b-col>
        </b-form-row>
    </div>
</template>

<script>
export default {
    props: {
        issue: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
        };
    },
    computed: {
        topLeft() {
            return [
                { id: 'id', label: 'Purchased item id:', value: this.issue.purchasedItemRecordId },
                { id: 'orderNumber', label: 'Order number:', value: this.issue.orderNumber},
                { id: 'trackingNubmer', label: 'Source tracking #:', value: this.issue.fromSourceTrackingNumber },
                { id: 'asin', label: 'Asin:', value: this.issue.asin },
                { id: 'sku', label: 'SKU:', value: this.issue.sku },
                { id: 'fnsku', label: 'FnSku:', value: this.issue.fnsku },
                { id: 'upc', label: 'UPC:', value: this.issue.upc },
            ]
        },
        topRight() {
            return [
                { id: 'purchasedQty', label: 'Purchased qty:', value: this.issue.purchasedQty },
                { id: 'receivedQty', label: 'Received qty:', value: this.issue.receivedQty },
                { id: 'damagedQty', label: 'Damaged qty:', value: this.issue.damagedQty },
                { id: 'extraQty', label: 'Extra qty:', value: this.issue.extraQty },
                { id: 'canceledQty', label: 'Canceled qty:', value: this.issue.canceledQty },
                { id: 'shippedQty', label: 'Shipped qty:', value: this.issue.shippedQty },
            ]
        },
        noHistory() {
            return this.issue.logs?.length === 0;
        }
    },
    methods: {
        convertNumberToBoolean(value) {
            return value === 1 ? "true": "false"
        },
    },
};
</script>

<style scoped>
.custom-modal .modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}

.custom-modal .modal-content {
  margin: auto; /* Center content horizontally */
}
.section-heading {
    color: dark gray;
    font-size: 1em;
    font-weight: 600;
}
</style>
