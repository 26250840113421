<template>
  <div>
    <b-form-group
      :id="id"
      :label="label"
      :label-for="`${id}-input`"
      :label-cols="labelCols"
      :description="error ? '' : description"
      :label-align="labelAlign"
      :label-size="size"
      :label-class="labelClass"
      class="label"
      :class="{ 'form-group--error': error, 'form-group--compact': compact }"
    >
      <b-form-spinbutton
        :id="`${id}-input`"
        :name="`${id}-input`"
        :value="value"
        @input="updateValue(id, $event)"
        v-bind="$attrs"
        :min="min"
        :max="max"
        :autofocus="true"
        :inline="inline"
        :size="size"
        :disabled="disabled"
        :readonly="readonly"
        :state="state"
        :style="{
          width: `${valueWidth}px`,
          marginLeft: `${marginLeftValue}px`,
        }"
        :aria-describedby="`${id}-live-feedback`"
        v-on="$listeners"
      ></b-form-spinbutton>
      <b-form-invalid-feedback :id="`${id}-live-feedback`">
        {{ errorMsg }}
      </b-form-invalid-feedback>
      <!-- </b-input-group> -->
    </b-form-group>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    autocomplete: { type: String, default: "off" },
    autofocus: { type: Boolean, default: false },
    compact: { type: Boolean, default: false },
    description: { type: String },
    error: { type: Boolean },
    errorMsg: { type: String },
    icon: { type: String, default: "" },
    id: { type: String },
    label: { type: String, default: "" },
    labelAlign: { type: String, default: "left" },
    labelClass: { type: String, default: "" },
    labelCols: { type: [String, Boolean, Number], default: "0" },
    inline: { type: Boolean, default: false },
    min: { type: Number, default: 0 },
    max: { type: Number, default: 100 },
    disabled: { type: [Boolean, null], default: false },
    readonly: { type: [Boolean, null], default: false },
    size: { type: String, default: "" },
    state: { type: [Boolean, null], default: null },
    value: [String, Number],
    valueWidth: { type: String, default: "125" },
    uppercase: { type: Boolean, default: false },
    marginLeftValue: { type: String, default: "0" }
  },
  methods: {
    updateValue(id, $event) {
      this.$emit("g3Input", { field: id, value: $event });
    }
  }
};
</script>

<style lang="css" scoped>
.form-group--compact {
  margin-bottom: .4rem;
}
</style>
