<template>
  <div>
    <b-row>
      <b-col cols="4">
        <div :class="actionClass(item.action)">{{ item.action }}</div>
        <div class="date">{{ fDate(item.ts) }}</div>
        <div class="user">{{ item.userId }}</div>
      </b-col>
      <b-col>
        <div v-html="logChanges(item.changes)"></div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { formatDateCustom } from "../utils/date-utils.js";
import { formatRawHtml, formatCrudAction } from "../utils/component-utils";

export default {
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  compted: {},
  methods: {
    fDate: function(rawDate) {
      //return "test";
      return formatDateCustom(rawDate, "M/D/YY hh:mm A");
    },
    logChanges: function(obj) {
      // convert json object to formatted text for display
      const result = formatRawHtml(obj);
      return result;
    },
    actionClass: function(action) {
      return formatCrudAction(action);
      // if (action === "Update") return "action-update";
      // else if (action === "Delete") return "action-delete";
      // return "action-insert";
    }
  }
};
</script>

<style lang="css" scoped>
div {
  font-size: 0.93em;
}
.date {
  font-size: 0.7em;
}
.user {
  font-size: 0.7em;
}
.action-update {
  font-weight: 700;
  color: rgb(0, 153, 255);
}
.action-insert {
  font-weight: 700;
  color: rgb(18, 133, 18);
}
.action-delete {
  font-weight: 700;
  color: rgb(212, 89, 89);
}

</style>