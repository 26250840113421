<template>
  <div>
    <b-form-group
      :id="id"
      :label="label"
      :label-for="`${id}-input`"
      :label-cols="labelCols"
      :description="error ? '' : description"
      :label-align="labelAlign"
      :label-size="size"
      :label-class="labelClass"
      class="label"
      :class="{ 'form-group--error': error, 'form-group--compact': compact }"
    >
      <b-form-select
        :id="`${id}-input`"
        :name="`${id}-input`"
        :ref="`${id}-input`"
        :options="getOptions()"
        @change="changeValue(id, $event)"
        v-bind="$attrs"
        :text-field="textField"
        :value-field="valueField"
        :plaintext="plaintext"
        :disabled="readonly"
        :required="required"
        :style="`width: ${textFieldWidth}px; margin-left: ${marginLeftValue}px`"
        :size="size"
        :state="state"
        :aria-describedby="`${id}-live-feedback`"
        v-on="$listeners"
      ></b-form-select>
      <b-form-invalid-feedback :id="`${id}-live-feedback`">{{
        errorMsg
      }}</b-form-invalid-feedback>
    </b-form-group>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    namespace: String,
    fieldName: String,
    compact: { type: Boolean, default: false },
    id: { type: String },
    description: { type: String },
    label: { type: String, default: "" },
    labelAlign: { type: String, default: "left" },
    labelCols: { type: [String, Boolean, Number], default: "0" },
    state: { type: [Boolean, null], default: null },
    plaintext: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    labelClass: { type: String, default: "" },
    size: { type: String, default: "" },
    error: { type: Boolean },
    options: { type: [Array, String], default: ()=>([]) },
    errorMsg: { type: String },
    uppercase: { type: Boolean, default: false },
    textFieldWidth: { type: String, default: "" },
    marginLeftValue: { type: String, default: "" },
    optionsField: { type: String, default: "options" },
    textField: {type: String, default: ""},
    valueField: {type: String, default: ""}
  },

  computed: {
    filters() {
      return this.$store.getters[`${this.namespace}/filters`];
    }
  },

  methods: {
    changeValue(id, $event) {
      this.$emit("g3Change", { field: id, value: $event });
    },
    getOptions() {
      // Get options from store
      const filters = this.filters;
      const filter = filters?.find(filter => filter.field === this.fieldName);
      return filter[this.optionsField] ? filter[this.optionsField] : this.options;
    }
  }
};
</script>

<style lang="css" scoped>
.form-group--compact {
  margin-bottom: .4rem;
}
</style>
