import CommonApi from "../../api/common.api";

// Common ACTIONS
const cApi = new CommonApi();

export const state = {
  items: [],
  currentItem: {},
  currentItemCopy: {},
  calcs: [],
  itemsLastRefreshed: "",
  selectedItems: [],
  // Controls BaseG3List
  routeName: "Unknown Route",
  documentTitle: "Unknown Title",
  keyField: "id",
  imageField: "imageUrl60",
  amazonLinkField: "asin",
  amazonUrlField: "amazonUrl",
  class: "g3table",
  style: "",
  showOtherActions: true,
  selectMode: "single",
  selectable: false,
  showAdd: true,
  showRefresh: true,
  showDrilldown: false,
  drilldownNamespace: "",
  drilldownId: -1,
  toggleDrilldown: false,
  allowInsert: false,
  showDelete: true,
  selectRowShowsDetail: false,
  //  fields: [],
  listName: "Unknown List",
  // Table width: At full screen, example: "80%"
  defaultContainerMaxWidth: "100%",
  // Table width: At 2150px, example: "90%"
  defaultContainerMinWidthAt2150px: "100%",
  // Table width: At 1920px, example: "100%"
  defaultContainerMinWidthAt1920px: "100%",
  // Optionally get calculated fields (for reports, for example, we won't)
  getCalculatedFields: true,
};

export const getters = {
  lastRefreshed: (state) => `Last refreshed: ${state.itemsLastRefreshed}`,
  emptyFilter: (state) => {
    // Enables filter clear button if isEmpty is false (something there):
    //  Nothing in search text, filter buttons, action buttons, selected
    state.list.searchText === "" &&
      state.filters.find((x) => x.value !== "" && x.value !== null) ===
        undefined &&
      state.list.selectedItems.length === 0;
  },
  filterIsEmpty: (state, getters) => getters.emptyFilter,
  currentItem: (state) => state.currentItem,
  selectedItems: (state) => state.list.selectedItems,
  filters: (state) => state.filters,
  logs: (state) => state.currentItem.logs,
  sidebarHidden: (state) => state.sidebarHidden,
  subNavTitle: (state) => state.subNavTitle,
};

export const actions = {
  basePrepareCurrentItem({ commit }) {
    // Clear assets
    commit("CURRENT_ITEM_SET", {});
    commit("CURRENT_ITEM_COPY_SET", {});
    commit("LOGS_SET", {});
  },
  baseSyncItemFromList({ commit, state }, itemId) {
    // Sync current item with matching items object
    if (state && state.list && state.items) {
      const findItem = state.items.find((x) => x.id === itemId);
      commit("CURRENT_ITEM_SET", findItem);
    }
  },
  async baseFetchItem({ dispatch, commit, state }, { endpoint, itemId }) {
    try {
      await dispatch("baseSyncItemFromList", itemId);
      // This ensures we have the record scope when retrieving data and options
      const params = `?tenantId=${state.currentItem.tenantId}&clientId=${state.currentItem.clientId}`;
      // Get data and logs
      const response = await cApi.crudGetOne(endpoint, itemId, params);
      const record = Array.isArray(response.data.record)
        ? response.data[0].record
        : response.data.record;
      commit("CURRENT_ITEM_SET_ASSIGN", record);
      // Make safety copy in case user cancels changes
      commit("CURRENT_ITEM_COPY_SET", record);
      // App logs for this item
      if (response.data.logs) {
        const logData = response.data.logs;
        commit("LOGS_SET", logData);
      }
      // Iterate over response to get options for each field
      if (response?.data?.options) {
        const options = response?.data?.options;
        Object.keys(options).forEach((key) => {
          commit("FILTER_ITEM_OPTIONS_SET", {
            fieldName: key,
            options: options[key],
          });
        });
        return response;
      }
    } catch (error) {
      console.error("Error fetching item:", error);
    }
  },
  async baseFetchAll({ dispatch, commit }, { endpoint, state }) {
    try {
      // Get items, options, and metadata (count, aggregates) in one http call
      await dispatch("Session/freezeIsLoading", true, {
        root: true,
      });
      // // Prepare for options
      // const selectedFields = state.filters
      //   .map((filter) => {
      //     if (
      //       (filter.type === "select" || filter.type === "action") &&
      //       filter.fetchOptions
      //     ) {
      //       return filter.field;
      //     }
      //     return null;
      //   })
      //   .filter(Boolean);
      // Get all records
      const props = {
        endpoint,
        pageSize: state.list.pageSize,
        currentPage: state.list.currentPage,
        sortBy: state.list.sortBy,
        sortOrder: state.list.sortOrder,
        orderClause: state.list.orderClause,
        searchText: state.list.searchText,
        filters: state.filters,
      };
      const response = await cApi.crudGetAll(props);
      // No results?
      if (response?.data?.message && response?.data?.message === "No results") {
        await commit("ITEMS_SET", {
          value: null,
        });
        await commit("LIST_SET", {
          key: "totalRows",
          value: 0,
        });
      } else {
        // See if we will be drilling down
        if (state.list.showDrilldown) {
          response?.data?.data?.forEach((item) => (item._showDetails = false));
        }
        if (response?.data.meta?.calcs) {
          await commit("LIST_SET", {
            key: "calcs",
            value: response?.data?.meta?.calcs,
          });
        }
        await commit("ITEMS_SET", {
          value: response?.data?.data,
        });
        await commit("LIST_SET", {
          key: "totalRows",
          value: response?.data?.meta?.totalCount,
        });
        await commit("LIST_SET", {
          key: "itemsLastRefreshed",
          value: new Date().toLocaleString(),
        });
        // Iterate over response to get options for each field
        if (response?.data?.options) {
          const options = response?.data?.options;
          Object.keys(options).forEach((key) => {
            commit("FILTER_OPTIONS_SET", {
              fieldName: key,
              options: options[key],
            });
          });
        }
      }
    } catch (error) {
      // handle the error here
      console.error(
        "Error fetching all items:",
        error?.data?.error ? error?.data?.error : error
      );
    } finally {
      await dispatch("Session/freezeIsLoading", false, {
        root: true,
      });
    }
  },

  async baseClearFilters(
    { commit, dispatch },
    { endpoint, fetchOnStart, state }
  ) {
    const filters = state.filters;
    // Clear selected
    await commit("LIST_SET", { key: "selectedItems", value: [] });
    // Clear paging
    await commit("LIST_SET", { key: "currentPage", value: 1 });
    // Clear search
    await commit("LIST_SET", { key: "searchText", value: "" });
    // Clear sort
    await commit("LIST_SET", {
      key: "sortBy",
      value: state.list.defaultSortBy || "",
    });
    await commit("LIST_SET", {
      key: "sortOrder",
      value: state.list.defaultSortOrder || "",
    });
    // Clear filter values
    state.filters.forEach(async (filter) => {
      // If filter has a default value, use that, otherwise, ""
      await commit("FILTER_SELECTED_SET", {
        fieldName: filter.field,
        value: filter.defaultValue ? filter.defaultValue : null,
      });
    });
    // Refresh list
    if (fetchOnStart === false) return;
    await dispatch("baseFetchAll", { endpoint, filters, state });
  },

  // Clear filter values only
  async baseClearFiltersOnly(context) {
    context.state.filters.forEach(async (filter) => {
      // If filter has a default value, use that, otherwise, ""
      await context.commit("FILTER_SELECTED_SET", {
        fieldName: filter.field,
        value: filter.defaultValue ? filter.defaultValue : null,
      });
    });
  },
  // Update unread flag in current item in db
  async baseItemUpdateUnreadFlag({ state }, { endpoint, record }) {
    if (state.currentItem.id !== record.id) {
      throw new Error("Current item doesn't match expected item id");
    }
    await cApi.crudUpdateOne({ endpoint, record });
  },
  async baseItemSave({ dispatch, state }, { endpoint, record }) {
    if (state.currentItem.id !== record.id) {
      throw new Error("Current item doesn't match expected item id");
    }
    const current = state.currentItem;
    const copy = state.currentItemCopy;
    const changes = await dispatch("getChanges", {
      current,
      copy,
    });
    if (!changes) {
      return "Nothing has changed";
    }
    const updatedItem = await cApi.crudUpdateOne({ endpoint, record: changes });
    return updatedItem;
  },
  async baseItemSaveDirect(_, { endpoint, record }) {
    const updatedItem = await cApi.crudUpdateOne({ endpoint, record });
    return updatedItem;
  },
  /**
   * Compares the current changes with original record and returns an object with just the
   * changes (along with key fields)
   * @param {obj} currentItem - Data in current record with potential changes
   * @param {obj} currentItemCopy - Original version of the record
   * @returns
   */
  async getChanges(_, { current, copy }) {
    const changes = {};
    for (const key in current) {
      if (Object.prototype.hasOwnProperty.call(current, key)) {
        if (Object.prototype.hasOwnProperty.call(copy, key)) {
          if (current[key] !== copy[key]) {
            changes[key] = current[key];
          }
        }
      }
    }
    if (Object.keys(changes).length === 0) {
      return null;
    }
    const keysToEnsure = ["id", "tenantId", "clientId"];
    keysToEnsure.forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(current, key)) {
        changes[key] = current[key];
      }
    });
    return changes;
  },
  currentItemFieldSet(context, payload) {
    context.commit("CURRENT_ITEM_FIELD_SET", payload);
  },
  listSet(context, payload) {
    context.commit("LIST_SET", payload);
  },
  // Action filter button set
  async setActionButton(context, payload) {
    await context.commit("ACTION_FILTER_SELECTED_SET", {
      fieldName: payload.fieldName,
      fieldValue: payload.fieldValue,
    });
    // Refresh list
    await context.dispatch("fetchAll", payload?.filter);
  },
};

export const mutations = {
  ACTION_FILTER_SELECTED_SET(state, data) {
    // Find filter field first
    const foundField = state.filters.find((x) => x.field === data.fieldName);
    // Set selected value of filter
    foundField.value = data.fieldValue;
  },
  CURRENT_ITEM_SET_ASSIGN(state, data) {
    // Refresh currentItm without loosing reference into items[]
    Object.assign(state.currentItem, data);
  },
  CURRENT_ITEM_SET(state, data) {
    state.currentItem = data;
  },
  CURRENT_ITEM_COPY_SET(state, data) {
    state.currentItemCopy = { ...data };
  },
  CURRENT_ITEM_FIELD_SET(state, data) {
    state.currentItem[data.key] = data.value;
  },
  CURRENT_ROUTE_SET(state, routeName) {
    state.list.routeName = routeName;
  },
  FILTER_OPTIONS_SET(state, data) {
    const filter = state.filters.find((x) => x.field === data?.fieldName);
    if (!filter) return;
    filter.options = data?.options;
  },
  FILTER_ITEM_OPTIONS_SET(state, data) {
    const filter = state.filters.find((x) => x.field === data?.fieldName);
    if (!filter) return;
    filter.itemOptions = data?.options;
  },
  FILTER_SELECTED_SET(state, data) {
    const filter = state.filters.find((x) => x.field === data.fieldName);
    filter.value = data.value;
    if (data.operator) {
      filter.operator = data.operator;
    }
    if (data.includeInTable != undefined) {
      filter.includeInTable = data.includeInTable;
    }
    if (data.setByUser != undefined) {
      filter.setByUsaer = data.setByUser;
    }
  },
  FILTER_INCLUDE_IN_TABLE_VALUE_SET(state, data) {
    const filter = state.filters.find((x) => x.field === data.key);
    filter.includeInTable = data?.value;
  },
  FILTER_DEFAULT_VALUE_SET(state, data) {
    const filter = state.filters.find((x) => x.field === data.key);
    filter.defaultValue = data.value;
  },
  FILTER_VALUE_SET(state, data) {
    const filter = state.filters.find((x) => x.field === data.key);
    filter.value = data.value;
  },
  ITEMS_SET(state, data) {
    state.items = data.value;
  },
  LIST_SET(state, data) {
    // data is key/value pair, like {key: 'sortOrder', value: 'DESC'}
    state.list[data.key] = data.value;
  },
  LOGS_SET(state, data) {
    state.currentItem.logs = data;
  },
  LOG_EVENT_SET(state, data) {
    // Add key/value pair to $logEvent or update if already there
    const { key, value } = data;
    if (!state.currentItem.$logEvent) {
      // Add key/value pair
      state.currentItem.$logEvent = [{ key: key, value: value }];
    } else {
      // $logEvent already exists, replace key/value pair if exists or add it if not
      if (!(key in state.currentItem.$logEvent)) {
        // Not yet in logEvent
        state.currentItem.$logEvent.push({ key: key, value: value });
      } else {
        // There, replace
        state.currentItem.$logEvent[key] = value;
      }
    }
  },
};
