<template>
  <div :style="cssVars" style="height:91vh">
    <!-- Table -->
    <b-row no-gutters class="table-container">
      <b-col>
        <BaseTableG3
          id="base-g3-list"
          :namespace="namespace"
          :route="list.routeName"
          :fields="list.fields"
          :keyField="list.keyField"
          :imageField="list.imageField"
          :class="list.class"
          :showOtherActions="list.showOtherActions && showOtherActionsButton"
          :showRefresh="list.showRefresh"
          :showDrilldown="list.showDrilldown"
          :drilldownNamespace="list.drilldownNamespace"
          :selectMode="list.selectMode"
          :selectable="list.selectable"
          :showAdd="isAuthorized(list.role, 'create') && showAddButton"
          :showLoadingSpinner="showLoadingSpinner"
          :allowInsert="
            isAuthorized(list.role, 'create') && allowInsertOperation
          "
          :allowDelete="
            isAuthorized(list.role, 'delete') && allowDeleteOperation
          "
          :selectRowShowsDetail="list.selectRowShowsDetail"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
//       style="max-width: 80%"

import { mapActions, mapGetters } from "vuex";

export default {
  components: {},
  props: {
    namespace: {
      type: String,
      default: ""
    },
    parentId: {
      type: Number,
      default: null
    },
    showLoadingSpinner: {
      type: Boolean,
      default: true
    },
    showAddButton: {
      type: Boolean,
      default: true
    },
    allowInsertOperation: {
      type: Boolean,
      default: true
    },
    allowDeleteOperation: {
      type: Boolean,
      default: true
    },
    showOtherActionsButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {};
  },
  created() {
    // Set parentId in vuex store if parentId is present (if this is a list of children)
    if (this.parentId) {
      this.$store.dispatch(`${this.namespace}/listSet`, {
        key: "parentId",
        value: this.parentId || -1
      });
    }
    // Set routeName in store
    this.$store.commit(`${this.namespace}/CURRENT_ROUTE_SET`, this.$route.name);
    // Set filters based on routeName
    this.$store.dispatch(`${this.namespace}/clearFiltersOnly`)
  },
  mounted() {
    document.title = this.list.documentTitle;
    // Set list name
    this.setSubNav(this.list.listName);
  },
  computed: {
    ...mapGetters("Session", ["isAuthorized"]),
    //    ...mapGetters("Session", ["setSubNav"]),
    list() {
      return this.$store.getters[`${this.namespace}/list`];
    },
    cssVars() {
      return {
        // Note to make this work, must specify ":style = 'cssVars'" in div element above.
        //Variables you want to pass to css
        "--default-container-max-width": this.list.defaultContainerMaxWidth,
        "--default-container-min-width-at-2150px": this.list
          .defaultContainerMinWidthAt2150px,
        "--default-container-min-width-at-1920px": this.list
          .defaultContainerMinWidthAt1920px
      };
    }
  },
  methods: {
    ...mapActions("Session", ["setSubNav"])
  }
};
</script>

<style lang="css" scoped>
@media only screen and (max-width: 2150px) {
  .table-container {
    min-width: var(--default-container-min-width-at-2150px);
    /* min-width: 90%; */
  }
}
@media only screen and (max-width: 1920px) {
  .table-container {
    min-width: var(--default-container-min-width-at-1920px);
    /* min-width: 100%; */
  }
}
.table-container {
  /* max-width: 80%; */
  max-width: var(--default-container-max-width);
  margin-left: auto;
  margin-right: auto;
}
.bv-example-row {
  height: 50px;
  background-color: rgb(175, 201, 209);
  border: 1px solid black;
  align-content: center;
}
</style>