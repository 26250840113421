// import the api endpoints
import router from "../../router";
import {
  apiHealthcheck,
  apiDbInfo,
  apiLogin,
  apiLogout,
} from "@/api/session.api";
import * as utils from "../../utils/utils";
import { apiRefreshToken } from "../../api/session.api";
import { handleRouterPushError } from "../../utils/component-utils";
import modules from "../modules";
//import { Vue } from "vue"

// State object
const initialState = () => ({
  auth: {},
  user: {},
  scope: "",
  permissions: [],
  isLoading: false,
  showLoading: true,
  isRefreshing: false,
  freezeIsLoading: false,
  subNav: "",
  accentColor: "125, 125, 125",
  dashboardPath: "/",
});
const state = initialState();

// Getter functions
const getters = {
  user: (state) => {
    return state.user;
  },
  auth: (state) => state.auth,
  authToken: (state) => state.auth.accessToken,
  authExpiryMinutes: (state) => state?.auth?.accessTokenExpiryMinutes,
  authExpiry: (state) => state.auth.expiry,
  customDashboard: (state) => state.user.customDashboard,
  role: (state) => state.user.role,
  isAuthenticated: (state) => !utils.isObjectEmpty(state.user),
  isAdmin: (state) => state.user.isAdmin,
  isLoading: (state) => state.isLoading && state.showLoading,
  showLoading: (state) => state.showLoading,
  isRefreshing: (state) => state.isRefreshing,
  subNav: (state) => state.subNav,
  accentColor: (state) => state.accentColor,
  //isAuthorized: (state) => (resource, action) => {
  isAuthorized: () => () => {
    return true;
    // TODO: Reinstate permissions
    // if (resource === "Session") return true;
    // let resourcePerm = null;
    // for (let i = 0; i < Object.keys(state.permissions).length; i++) {
    //   if (state.permissions[i].resource === resource) {
    //     resourcePerm = state.permissions[i];
    //     break;
    //   }
    // }
    // //const resourcePerm = state.permissions.find(x=>x.resource === resource)
    // if (resourcePerm === null) {
    //   // No permission for this resource
    //   return false;
    // }
    // // Resource permitted; check rights
    // return resourcePerm[action];
  },
  dashboardPath: (state) => state.dashboardPath,
};

// Actions
const actions = {
  async fetchHealthcheck() {
    const response = await apiHealthcheck();
    return response;
  },
  async fetchDbInfo() {
    const response = await apiDbInfo();
    return response;
  },
  async login({ commit, dispatch, state }, { userId, password }) {
    const response = await apiLogin(userId, password);
    commit("SET_AUTH", { ...response?.data?.auth });
    const authExpiry = utils.addMinutes(state?.auth?.accessTokenExpiryMinutes);
    commit("SET_AUTH_EXPIRY", authExpiry);
    commit("SET_USER", { ...response?.data?.user });
    commit("SET_PERMISSIONS", { ...response?.data?.permissions });
    commit("SET_SCOPE", response?.data?.user.scope);

    // Initialize each store after login to accurately determine user scope (tenant or client)
    const moduleNames = Object.keys(modules);
    for (const moduleName of moduleNames) {
      if (modules[moduleName].actions?.initializeStore) {
        await dispatch(`${moduleName}/initializeStore`, null, { root: true });
      }
    }
  },
  async initializeStore() {},
  // Refresh access token
  async refreshToken({ state, commit }) {
    const response = await apiRefreshToken(state.user);
    commit("SET_AUTH", { ...response?.data?.auth });
    const authExpiry = utils.addMinutes(state?.auth?.accessTokenExpiryMinutes);
    commit("SET_AUTH_EXPIRY", authExpiry);
  },

  async logout({ dispatch }, doApiLogout) {
    await router.push({ path: "/login" }).catch((err) => {
      handleRouterPushError(err);
    });
    if (doApiLogout) {
      await apiLogout();
    }
    dispatch("reset", {}, { root: true });
  },
  toDashboard({ commit, getters }) {
    // Route to dashboard component based on resource permissions
    if (getters.isAuthorized("DashboardSlcPrep", "read")) {
      //TODO: Once we turn IsAuthorized back on, set up permissions for dashboards.
      commit("SET_DASHBOARD_PATH", "/");
      //      commit("SET_DASHBOARD_PATH", "/DashboardSlcPrep");
    } else {
      commit("SET_DASHBOARD_PATH", "/");
    }
  },
  reset({ commit }) {
    commit("RESET");
  },

  // Accent color is defined in compont-utils.js
  setAccentColor({ commit }, payload) {
    commit("SET_ACCENT_COLOR", payload);
  },
  // setDashboardPath({ commit }, payload) {
  //   commit("SET_DASHBOARD_PATH", payload);
  // },
  setIsLoading({ commit }, payload) {
    commit("SET_LOADING", payload);
  },
  freezeIsLoading({ commit }, payload) {
    commit("SET_FREEZE_IS_LOADING", payload);
  },
  setIsRefreshing({ commit }, payload) {
    commit("SET_REFRESHING", payload);
  },
  setPermissions({ commit }, payload) {
    commit("SET_PERMISSIONS", payload);
  },
  setShowLoading({ commit }, payload) {
    commit("SET_SHOW_LOADING", payload);
  },
  setSubNav({ commit }, payload) {
    commit("SET_SUBNAV", payload);
  },
};

// Mutations
const mutations = {
  RESET(state) {
    Object.assign(state, initialState());
  },
  SET_ACCENT_COLOR(state, data) {
    state.accentColor = data;
  },
  SET_AUTH(state, data) {
    state.auth = data;
  },
  SET_AUTH_EXPIRY(state, data) {
    state.auth.expiry = new Date(data).toISOString();
  },
  SET_DASHBOARD_PATH(state, data) {
    state.dashboardPath = data;
  },
  SET_LOADING(state, data) {
    // Don't turn off is loading if overridden
    if (data === false && state.freezeIsLoading) {
      return;
    }
    state.isLoading = data;
  },
  SET_FREEZE_IS_LOADING(state, data) {
    state.freezeIsLoading = data;
    if (data === false) {
      state.isLoading = false;
    }
  },
  SET_PERMISSIONS(state, data) {
    state.permissions = data;
  },
  SET_REFRESHING(state, data) {
    state.isRefreshing = data;
  },
  SET_SCOPE(state, data) {
    state.scope = data;
  },
  SET_SHOW_LOADING(state, data) {
    state.showLoading = data;
  },
  SET_SUBNAV(state, data) {
    state.subNav = data;
  },
  SET_USER(state, data) {
    state.user = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
