import CommonApi from "../../api/common.api";
import * as cGetters from "../common/getters";
import * as cActions from "../common/actions";

const cApi = new CommonApi("issue");
const cApiComments = new CommonApi("issue/comment");

// State object
const initialState = () => {
  return {
    list: {
      items: [],
      selectedItems: [],
      totalRows: 0,
      pageSize: 50,
      currentPage: 1,
      defaultSortBy: "ts",
      defaultSortOrder: "DESC",
      sortBy: "ts",
      sortOrder: "DESC",
      searchText: "",
      itemsLastRefreshed: "",
      calcs: [],
    },
    currentItem: {},
    logs: [],
    subNavTitle: "Issues",
    sidebarHidden: false,
    filters: [
      {
        type: "select",
        field: "originator",
        label: "Originator",
        options: [],
        includeInTable: true,
        value: null,
        operator: "IN",
        multiSelect: false,
        allowBulkChange: true,
      },
      {
        type: "select",
        field: "facility",
        label: "Facility",
        includeInTable: false,
        options: [],
        value: null,
        operator: "IN",
        multiSelect: false,
        allowBulkChange: true,
      },
      {
        type: "select",
        field: "status",
        label: "Status",
        includeInTable: false,
        options: [],
        value: null,
        operator: "=",
        multiSelect: false,
        allowBulkChange: true,
      },
      {
        type: "select",
        field: "type",
        label: "Type",
        includeInTable: true,
        options: [],
        value: null,
        operator: "IN",
        multiSelect: false,
        allowBulkChange: true,
      },
      {
        type: "select",
        field: "subType",
        label: "Sub-type",
        includeInTable: true,
        includeInRoutes: ["swissue"],
        options: [],
        value: null,
        operator: "IN",
        multiSelect: false,
        allowBulkChange: true,
      },
      {
        type: "select",
        field: "priority",
        label: "Priority",
        includeInTable: true,
        options: [],
        value: null,
        operator: "IN",
        multiSelect: false,
        allowBulkChange: true,
      },
      {
        type: "select",
        field: "assignee",
        label: "Assignee",
        includeInTable: true,
        options: [],
        value: null,
        operator: "IN",
        multiSelect: false,
        allowBulkChange: true,
      },
      {
        type: "select",
        field: "assigneeUserId",
        label: "Assignee User Id",
        includeInTable: false,
        fetchOptions: false,
        options: [],
        value: null,
        operator: "IN",
        multiSelect: false,
        allowBulkChange: false,
      },
      {
        type: "select",
        field: "organizationName",
        label: "Organization",
        includeInTable: true,
        fetchOptions: true,
        options: [],
        value: null,
        operator: "IN",
        multiSelect: false,
        allowBulkChange: false,
      },
      {
        type: "select",
        field: "lob",
        label: "LOB",
        includeInTable: false,
        options: [],
        value: null,
        operator: "IN",
        multiSelect: false,
        allowBulkChange: true,
      },
      // {
      //   type: "action",
      //   field: "alert",
      //   label: "Alert",
      //   value: "null",
      //   defaultValue: "All",
      //   operator: "=",
      //   options: [
      //     { text: "Yes", value: "1" },
      //     { text: "No", value: "0" },
      //     { text: "All", value: null },
      //   ],
      // },
    ],
  };
};

const state = initialState();

// Getter functions
const getters = {
  comments(state) {
    return state.currentItem.detail;
  },
  currentItem(state) {
    return state.currentItem;
  },
  attachments(state) {
    if (!state.currentItem.attachments) {
      return [];
    }
    return state.currentItem.attachments;
  },
  selectedItems(state) {
    return state.list.selectedItems;
  },
  filterIsEmpty(state) {
    return cGetters.emptyFilter(state);
  },
  filters(state) {
    return state.filters;
  },
  lastRefreshed(state) {
    return cGetters.lastRefreshed(state);
  },
  list(state) {
    return state.list;
  },
  logs(state) {
    return state.logs;
  },
  sidebarHidden(state) {
    return state.sidebarHidden;
  },
  subNavTitle(state) {
    return state.subNavTitle;
  },
};

// Actions
const actions = {
  async fetchItem({ commit, dispatch }, id) {
    // Find id in list and set to current item to show record sooner; then refresh
    await cActions.fetchItem(cApi, commit, id, true);
    await dispatch("addComment");
  },

  async fetchAll({ state, commit, dispatch, rootState }, routeName) {
    // Clear filters
    //await cActions.clearFiltersOnly(state, commit);
    // Set filter?
    if (routeName === "QpIssueListAllOpen") {
      // For QP issues - all open **************************************************
      await dispatch("Session/setSubNav", "QikPrep Issues - All Open", {
        root: true,
      });
      commit("FILTER_SELECTED_SET", {
        fieldName: "lob",
        operator: "NOT IN",
        value: "Software",
      });
      commit("FILTER_SELECTED_SET", {
        fieldName: "facility",
        value: "WHS-QP",
      });
      commit("FILTER_SELECTED_SET", {
        fieldName: "status",
        operator: "NOT IN",
        value: "Complete",
      });
      commit("FILTER_SELECTED_SET", {
        fieldName: "type",
        operator: "LIKE",
        value: "",
        includeInTable: true,
      });
    } else if (routeName === "QpIssueListMyOpen") {
      // For qp issues - my open (assigned to me)
      await dispatch(
        "Session/setSubNav",
        "QikPrep Issues - Open Assigned to Me",
        {
          root: true,
        }
      );
      commit("FILTER_SELECTED_SET", {
        fieldName: "lob",
        operator: "NOT IN",
        value: "Software",
      });
      commit("FILTER_SELECTED_SET", {
        fieldName: "facility",
        value: "WHS-QP",
      });
      commit("FILTER_SELECTED_SET", {
        fieldName: "status",
        operator: "NOT IN",
        value: "Complete",
      });
      commit("FILTER_SELECTED_SET", {
        fieldName: "assigneeUserId",
        value: rootState.Session.user.userId,
      });
    } else if (routeName === "QpIssueListMyOrgOpen") {
      // For qp issues - my org's open (assigned to my organization)
      await dispatch(
        "Session/setSubNav",
        "QikPrep Issues - Open Assigned to my Group",
        {
          root: true,
        }
      );
      commit("FILTER_SELECTED_SET", {
        fieldName: "lob",
        operator: "NOT IN",
        value: "Software",
      });
      commit("FILTER_SELECTED_SET", {
        fieldName: "facility",
        operator: "IN",
        value: "WHS-QP",
      });
      commit("FILTER_SELECTED_SET", {
        fieldName: "status",
        operator: "NOT IN",
        value: "Complete",
      });
      commit("FILTER_SELECTED_SET", {
        fieldName: "organizationName",
        operator: "IN",
        value: rootState.Session.user.organizationName,
      });
      // } else if (routeName === "QpIssueListOverShipped") {
      //   // For qp issues - over-shipped
      //   await dispatch("Session/setSubNav", "QikPrep Issues - Over-shipped", {
      //     root: true,
      //   });
      //   commit("FILTER_SELECTED_SET", {
      //     fieldName: "lob",
      //     operator: "NOT IN",
      //     value: "Software",
      //     includeInTable: false,
      //   });
      //   commit("FILTER_SELECTED_SET", {
      //     fieldName: "facility",
      //     operator: "IN",
      //     value: "WHS-QP",
      //     includeInTable: false,
      //   });
      //   commit("FILTER_SELECTED_SET", {
      //     fieldName: "status",
      //     operator: "NOT IN",
      //     value: "Complete",
      //     includeInTable: false,
      //   });
      //   commit("FILTER_SELECTED_SET", {
      //     fieldName: "type",
      //     operator: "=",
      //     value: "Over Shipped",
      //     includeInTable: false,
      //   });
    } else if (routeName === "QpIssueListClosed") {
      // For qp issues - my open (assigned to me)
      await dispatch("Session/setSubNav", "QikPrep Issues - All Closed", {
        root: true,
      });
      commit("FILTER_SELECTED_SET", {
        fieldName: "lob",
        operator: "NOT IN",
        value: "Software",
      });
      commit("FILTER_SELECTED_SET", {
        fieldName: "facility",
        operator: "IN",
        value: "WHS-QP",
      });
      commit("FILTER_SELECTED_SET", {
        fieldName: "status",
        operator: "IN",
        value: "Complete",
      });
    } else if (routeName === "SwIssueOpenAll") {
      // For SW issues - all open **************************************************
      await dispatch("Session/setSubNav", "Software Issues - All Open", {
        root: true,
      });
      commit("FILTER_SELECTED_SET", {
        fieldName: "lob",
        operator: "IN",
        value: "Software",
      });
      commit("FILTER_SELECTED_SET", {
        fieldName: "facility",
        operator: "LIKE",
        value: "",
      });
      commit("FILTER_SELECTED_SET", {
        fieldName: "status",
        operator: "NOT IN",
        value: "Complete",
      });
    } else if (routeName === "SwIssueOpenMy") {
      // For SW issues - my open (assigned to me)
      await dispatch(
        "Session/setSubNav",
        "Software Issues - Open Assigned to Me",
        {
          root: true,
        }
      );
      commit("FILTER_SELECTED_SET", {
        fieldName: "lob",
        operator: "IN",
        value: "Software",
      });
      commit("FILTER_SELECTED_SET", {
        fieldName: "status",
        operator: "NOT IN",
        value: "Complete",
      });
      commit("FILTER_SELECTED_SET", {
        fieldName: "facility",
        operator: "LIKE",
        value: "",
      });
      commit("FILTER_SELECTED_SET", {
        fieldName: "assigneeUserId",
        operator: "IN",
        value: rootState.Session.user.userId,
      });
    } else if (routeName === "SwIssueClosed") {
      // For SW issues - closed
      await dispatch("Session/setSubNav", "Software Issues - All Closed", {
        root: true,
      });
      commit("FILTER_SELECTED_SET", {
        fieldName: "lob",
        operator: "IN",
        value: "Software",
      });
      commit("FILTER_SELECTED_SET", {
        fieldName: "status",
        operator: "IN",
        value: "Complete",
      });
    }
    await cActions.fetchAll(cApi, state, commit, dispatch);
  },

  // Fetch filter options
  async fetchOptions({ state, commit, dispatch }) {
    await cActions.fetchOptions(cApi, state, commit, dispatch);
  },

  // Fetch filter options for specific filter field
  async fetchOptionsForField({ state, commit, dispatch }, field) {
    return await cActions.fetchOptionsForFilter(
      cApi,
      state,
      commit,
      dispatch,
      field
    );
  },

  async itemCreate({ state, commit, dispatch }) {
    return await cActions.itemCreate(
      cApi,
      commit,
      dispatch,
      state.currentItem,
      "issue"
    );
  },

  // Create an issue with passed in item
  async itemCreateRemote({ commit, dispatch }, item) {
    return await cActions.itemCreate(cApi, commit, dispatch, item, "issue");
  },

  async itemSave({ state, commit, dispatch, rootState }, payload) {
    const item = payload?.item || null;
    const postToast = payload?.postToast || null;
    await dispatch("saveComments");
    // Increment version if passed in item or if current item
    if (item) {
      item.version++;
      item.lastUpdatedBy = rootState.Session.user.familiarName;
    } else {
      // Current item
      commit("CURRENT_ITEM_FIELD_SET", {
        key: "version",
        value: state.currentItem.version + 1,
      });
      commit("CURRENT_ITEM_FIELD_SET", {
        key: "lastUpdatedBy",
        value: rootState.Session.user.familiarName,
      });
    }
    const useItem = item ? item : state.currentItem;
    return await cActions.itemSave(
      cApi,
      commit,
      dispatch,
      useItem,
      `Issue ${state.currentItem.id}`,
      postToast
    );
  },

  async saveComments({ state, commit, dispatch, rootState }) {
    // Update EXISTING comments changed and insert any new comments
    if (!state.currentItem.detail) return;
    const updatedComments = state.currentItem?.detail?.filter(
      (x) => x.dirty && x.id > -1
    );
    if (updatedComments?.length > 0) {
      // Update changed comments, one by one
      for (const comment of updatedComments) {
        commit("CURRENT_ITEM_COMMENT_FIELD_SET", {
          id: comment.id,
          key: "lastUpdatedBy",
          value: rootState.Session.user.familiarName,
        });
        await cActions.itemSaveSpecial(cApiComments, dispatch, comment);
      }
    }
    // Add NEW comments? (only 1 new comment at a time)
    const newComment = state.currentItem.detail.find(
      (x) => x.id === -1 && x.comment
    );
    if (newComment) {
      newComment.lastUpdatedBy = rootState.Session.user.familiarName;
      commit("CURRENT_ITEM_COMMENT_UPDATE_USERID_SET", {
        comment: newComment,
        userId: rootState.Session.user.familiarName,
      });
      const savedComment = await cActions.itemCreateSpecial(
        cApiComments,
        dispatch,
        newComment
      );
      commit("CURRENT_ITEM_COMMENT_UPDATE_ID_SET", {
        comment: newComment,
        id: savedComment.id,
      });
    }
  },
  async addComment({ state, commit, rootState }) {
    // If already has a new comment, skip
    if (state.currentItem?.detail?.find((x) => x.userId === "New Comment"))
      return;
    const issueId = state.currentItem.id;
    const lastUpdatedBy = rootState.Session.user.familiarName;
    const newComment = {
      id: -1,
      issueId,
      userId: "New Comment",
      comment: "",
      lastUpdatedBy,
    };
    commit("CURRENT_ITEM_COMMENT_SET", newComment);
  },
  // Delete comment
  async deleteComment({ commit, dispatch }, comment) {
    await cActions.deleteItemSpecial(
      cApiComments,
      commit,
      dispatch,
      comment,
      "CURRENT_ITEM_COMMENT_DELETE_SET"
    );
  },
  async updateComment({ commit, rootState }, data) {
    // Add comment to detail; can be new or existing
    data.userId =
      data.userId === "New Comment"
        ? rootState.Session.user.familiarName
        : data.userId;
    data.lastUpdatedBy = rootState.Session.user.familiarName;
    commit("CURRENT_ITEM_COMMENT_UPDATE_SET", data);
  },

  async deleteSelectedItems({ commit, state, dispatch }) {
    await cActions.deleteSelectedItems(cApi, commit, state, dispatch);
  },

  async clearFilters({ state, commit, dispatch }, filter) {
    await cActions.clearFilters(state, commit, dispatch, filter);
  },

  // Action filter button set
  async setActionButton({ commit, dispatch }, payload) {
    await cActions.setActionButton(commit, dispatch, payload);
  },

  // Dropdown filter set
  async filterSelectedItemsSet({ commit, dispatch }, payload) {
    await cActions.filterSelectedItemsSet(commit, dispatch, payload);
  },
  currentItemSet({ commit }, payload) {
    commit("CURRENT_ITEM_SET", payload);
  },
  currentItemFieldSet({ commit }, payload) {
    commit("CURRENT_ITEM_FIELD_SET", payload);
  },
  listSet({ commit }, payload) {
    commit("LIST_SET", payload);
  },
  reset({ commit }) {
    commit("RESET");
  },
  sidebarHidden({ commit }, payload) {
    commit("SIDEBAR_HIDDEN_SET", payload);
  },
};

// Mutations
const mutations = {
  HAS_ATTACHMENTS(state, data) {
    state.currentItem.hasAttachments = data;
  },
  ATTACHMENT_ADD(state, data) {
    if (!state.currentItem.attachments) state.currentItem.attachments = [];
    state.currentItem.attachments.push(data);
  },
  ATTACHMENT_REMOVE(state, data) {
    if (!state.currentItem.attachments) return;
    if (!Array.isArray(state.currentItem.attachments)) return;
    state.currentItem.attachments = state.currentItem.attachments.filter(
      (file) => file.thumbnailKey !== data
    );
  },
  ACTION_FILTER_SELECTED_SET(state, data) {
    // Find filter field first
    const foundField = state.filters.find((x) => x.field === data.fieldName);
    // Set selected value of filter
    foundField.value = data.fieldValue;
  },
  CLEAR_SELECTED_ITEMS(state) {
    state.selectedItems = [];
  },
  CLEAR_BASE_FILTERS(state) {
    // facility

    let filter = state.filters.find((x) => x.field === "facility");
    filter.value = null;
    // status
    filter = state.filters.find((x) => x.field === "status");
    filter.value = null;
    // assigneeUserId
    filter = state.filters.find((x) => x.field === "assigneeUserId");
    filter.value = null;
    // organizationName
    // filter = state.filters.find((x) => x.field === "organizationName");
    // filter.value = null;
  },
  CURRENT_ITEM_SET(state, data) {
    state.currentItem = data;
  },
  CURRENT_ITEM_FIELD_SET(state, data) {
    state.currentItem[data.key] = data.value;
  },
  CURRENT_ITEM_COMMENT_FIELD_SET(state, data) {
    const foundComment = state.currentItem.detail.find((x) => x.id === data.id);
    if (!foundComment) return;
    foundComment[data.key] = data.value;
  },
  CURRENT_ITEM_COMMENT_SET(state, data) {
    if (state.currentItem.detail) {
      state.currentItem.detail.push(data);
    } else {
      state.currentItem.detail = [data];
    }
  },
  CURRENT_ITEM_COMMENT_DELETE_SET(state, data) {
    state.currentItem.detail = state.currentItem.detail.filter(
      (x) => x.id !== data.id && x.issueId === data.issueId
    );
  },
  CURRENT_ITEM_COMMENT_UPDATE_SET(state, data) {
    const foundComment = state.currentItem.detail.find((x) => x.id === data.id);
    if (!foundComment) return;
    foundComment.userId = data.userId;
    foundComment.comment = data.comment;
    foundComment.lastUpdatedBy = data.lastUpdatedBy;
    foundComment.dirty = true;
  },
  CURRENT_ITEM_COMMENT_UPDATE_USERID_SET(state, data) {
    data.comment.userId = data.userId;
    data.comment.lastUpdatedBy = data.userId;
  },
  CURRENT_ITEM_COMMENT_UPDATE_ID_SET(state, data) {
    data.comment.id = data.id;
  },
  FILTER_OPTIONS_SET(state, data) {
    const filter = state.filters.find((x) => x.field === data.fieldName);
    filter.options = data.options;
  },
  FILTER_SELECTED_SET(state, data) {
    const filter = state.filters.find((x) => x.field === data.fieldName);
    filter.value = data.value;
    if (data.operator) {
      filter.operator = data.operator;
    }
    if (data.includeInTable != undefined) {
      filter.includeInTable = data.includeInTable;
    }
    if (data.setByUser != undefined) {
      filter.setByUsaer = data.setByUser;
    }
  },
  ITEM_REMOVE(state, idToRemove) {
    state.list.items = state.list.items.filter(
      (item) => item.id !== idToRemove
    );
  },
  LIST_SET(state, data) {
    // data should be {key: 'sortOrder', value: 'DESC'}
    state.list[data.key] = data.value;
  },
  LOGS_SET(state, data) {
    state.logs = data;
  },
  RESET(state) {
    Object.assign(state, initialState());
  },
  SIDEBAR_HIDDEN_SET(state, data) {
    state.sidebarHidden = data;
  },
  SUB_NAV_SET(state, data) {
    state.subNavTitle = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
