import axios from "axios";
import store from "../store";

// process.env.VUE_APP_BASE_URL
// https://web-production-1c42b.up.railway.app
const httpClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 30000,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    credentials: "include",

    // anything you want to add to the headers
  },
});

// *************************************
// Interceptor to inject auth token in request

const getToken = async () => {
  const token = store.getters["Session/authToken"];
  if (!token) {
    // No token yet
    return null;
  }
  const auth = store.getters["Session/auth"];
  const currentDate = new Date().toISOString();
  if (currentDate <= auth.expiry) {
    // Token is valid
    return token;
  }
  // Access token has expired: Refresh
  await store.dispatch("Session/refreshToken");
  const newToken = await store.getters["Session/authToken"];
  return newToken;
};

const authInterceptor = async (config) => {
  // Get current access token expiry and see if it has expired
  // If so, call refresh-token
  // Loading data
  store.dispatch("Session/setIsLoading", true);
  //const token = store.getters["Session/authToken"]
  const token = await getToken();
  config.headers["Authorization"] = `Bearer ${token}`;
  return config;
};
httpClient.interceptors.request.use(authInterceptor);

// *************************************
// Interceptor to catch errors in response
const errorInterceptor = async (error) => {
  await store.dispatch("Session/setIsLoading", false);
  if (!error.response) {
    // Server error
    toastError(error);
    return Promise.reject(error);
  }
  // all the other error responses
  let reason = "";
  switch (error?.response?.status) {
    case 400:
      toastError(error);
      alertError(error);
      break;
    case 401:
      // Authentication error
      reason = error?.response?.headers["x-reason"];
      if (reason && reason === "401.1") {
        // Invalid user id or password
        alertError(error);
      } else {
        // No reason code or token expired: Log out
        // 401.2 - Access token expired
        // 401.3 - Refresh token expired
        // 401.4 - Refresh token blacklisted (user logged out)
        logout();
        toastErrorCustom("Session timed out", "Please log in to continue");
      }
      break;
    default:
      toastError(error);
  }
  return Promise.reject(error?.response);
};

// Interceptor for responses
const responseInterceptor = (response) => {
  store.dispatch("Session/setIsLoading", false);
  return response;
};

async function logout() {
  await store.dispatch("auth/logout", false);
}

// Set to track shown errors
const shownErrors = new Set();

function toastError(error) {
  const message = !error?.response?.data?.message
    ? `${error.message}. Is ${process.env.VUE_APP_BASE_URL} running? `
    : error?.response?.data?.message;
  const title = !error?.response?.status
    ? "Status unknown"
    : `Status ${error?.response?.status}`;
  // Create a unique identifier for the error
  const errorKey = `${title}: ${message}`;
  // Only show if we haven't seen it before.
  if (!shownErrors.has(errorKey)) {
    shownErrors.add(errorKey);
    store.dispatch(
      "Notification/toastErrorAdd",
      {
        message,
        title,
      },
      { root: true }
    );
  }
}

// Function to clear the shown errors
function clearShownErrors() {
  shownErrors.clear();
}
// Clear shown errors every 15 seconds
setInterval(clearShownErrors, 5000);

function toastErrorCustom(title, message) {
  store.dispatch(
    "Notification/toastInfoAdd",
    {
      message,
      title,
    },
    { root: true }
  );
}

function alertError(error) {
  store.dispatch(
    "Notification/alertErrorAdd",
    {
      message: error?.response?.data?.error,
      show: true,
    },
    { root: true }
  );
  //   const message = !error?.response?.data?.message
  //     ? `${error.message}. Is ${process.env.VUE_APP_BASE_URL} running? `
  //     : error?.response?.data?.message
  //   const status = !error?.response?.status
  //     ? "Status unknown"
  //     : `Status ${error?.response?.status}`
  //   const useMessage = `${message}. ${status}`
  //   store.dispatch(
  //     "Notification/alertErrorAdd",
  //     {
  //       message: useMessage,
  //       show: true,
  //     },
  //     { root: true }
  //   )
}

httpClient.interceptors.response.use(responseInterceptor, errorInterceptor);

export { httpClient, getToken };
