<template>
  <div>
    <b-form-group
      :id="id"
      :label="label"
      :label-for="`${id}-datepicker`"
      :label-cols="labelCols"
      :description="error ? '' : description"
      :label-align="labelAlign"
      :label-size="size"
      :label-class="labelClass"
      class="label"
      :class="{ 'form-group--error': error, 'form-group--compact': compact }"
    >
      <b-form-datepicker
        :id="`${id}-datepicker`"
        :name="`${id}-datepicker`"
        :readonly="readonly"
        :disabled="disabled"
        :size="size"
        :placeholder="placeholder"
        reset-button
        :dark="dark"
        :value="value"
        :state="state"
        @input="updateValue(id, $event)"
        v-bind="$attrs"
        :aria-describedby="`${id}-live-feedback`"
        v-on="$listeners"
      ></b-form-datepicker>
      <b-form-invalid-feedback :id="`${id}-live-feedback`">{{
        errorMsg
      }}</b-form-invalid-feedback>
    </b-form-group>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    compact: { type: Boolean, default: false },
    id: { type: String },
    description: { type: String },
    label: { type: String, default: "" },
    labelAlign: { type: String, default: "left" },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    size: { type: String, default: "" },
    labelCols: { type: [Boolean, String, Number, null], default: null },
    placeholder: { type: String, default: "" },
    dark: { type: Boolean, default: false },
    labelClass: [String],
    value: [String, Number],
    state: [Boolean, null],
    error: { type: Boolean },
    errorMsg: { type: String }
  },
  methods: {
    updateValue(id, $event) {
      this.$emit("g3Input", { field: id, value: $event });
    }
  }
};
</script>

<style lang="css" scoped>
.form-group--compact {
  margin-top: .4rem;
  margin-bottom: .4rem;
}
</style>
