<template>
  <div>
    <label class="d-inline-block mr-2 small" for="rowsPerPage">Page size</label>
    <b-form-select
      :value="list.pageSize"
      name="rowsPerPage"
      class="d-inline-block custom-select"
      style="width: auto"
      size="sm"
      @change="localPageSizeSet"
    >
      <option>10</option>
      <option>25</option>
      <option>50</option>
      <option>100</option>
      <option>200</option>
      <option>5000</option>
    </b-form-select>
  </div>
</template>

<script>
export default {
  props: {
    namespace: {
      type: String,
      default: ""
    }
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
    list() {
      return this.$store.getters[`${this.namespace}/list`];
    }
  },
  methods: {
    localPageSizeSet(e) {
      console.log("localpagesizeset: e", )
      this.$store.dispatch(`${this.namespace}/listSet`, {
        key: "pageSize",
        value: parseInt(e)
      });
      this.$store.dispatch(`${this.namespace}/fetchAll`, this.routeName);
    }
  }
};
</script>

<style lang="css" scoped>
.custom-select{
  font-size: 10pt;
}
</style>