<!-- IssueItem.vue -->
<template >
	<b-container fluid class="item-wrapper">
		<div>
			<b-overlay
				variant="white"
				:show="isLoading && showLoading"
				:opacity="0.0"
				spinner-variant="info"
			>
				<BaseAlert :mode="mode" width="35em" :timeout="7" />
				<b-form
					@submit.prevent="formSave"
					@keydown.enter.exact="formSave"
					@keydown.esc.exact="formCancel"
					enctype="multipart/form-data"
				>
					<!-- Main form card -->
					<b-card
						no-body
						header-tag="header"
						footer-tag="footer"
						class="no-border"
					>
						<!-- Header -->
						<template #header>
							<div class="item-modal-header-content">
								<!-- Record id -->
								<div>
									<span class="mr-1 text-muted small">
										{{ mode === "new" ? "New Issue" : `Issue id:` }}</span
									>
									<span class="small">{{ form.id }}</span>
								</div>
								<!-- Description  -->
								<h5 class="item-header-description">
									{{ headerDescription() }}
								</h5>
								<button
									@click="formCancel"
									class="item-close-btn"
									aria-label="Close"
								>
									&times;
								</button>
							</div>
						</template>
						<!-- Card body slot -->
						<b-card no-body class="bg-light">
							<b-tabs
								card
								nav-class="text-capitalize"
								small
								active-nav-item-class="font-weight-bold text-uppercase text-info"
								content-class="mt-0 p-2"
							>
								<!-- MAIN TAB -->
								<b-tab lazy title="Main" class="bg-light">
									<template>
										<b-form-row>
											<!-- Top row, full width -->
											<b-col cols="8" class="mr-2 pr-3">
												<!-- Reason -->
												<BaseFormTextarea
													id="issueReason"
													autofocus
													placeholder="Issue reason"
													autocomplete="off"
													label="Issue reason:"
													v-model="form.issueReason"
													:state="getFieldState('issueReason')"
													@input="$v.form.issueReason.$touch()"
													@keydown.enter.stop
													:readonly="!isAuthorized('Issue', 'update')"
													:error="$v.form.issueReason.$error"
													errorMsg="Issue reason is required"
													size="sm"
													:rows="3"
												/>
												<h6
													class="comments-heading"
													v-if="
														isAuthorized('IssueComment', 'read') &&
														mode !== 'new'
													"
												>
													Comments
												</h6>
												<div class="comments-scrollable-list">
													<IssueComments
														:namespace="namespace"
														:comments="comments"
														:user="user"
														@g3CommentDirty="handleCommentDirty"
														@g3CommentDeleted="handleCommentDeleted"
														@g3CommentBlurred="handleCommentBlurred"
														v-if="
															isAuthorized('IssueComment', 'read') &&
															mode !== 'new'
														"
													/>
												</div>
											</b-col>
											<b-col>
												<!-- Status -->
												<BaseFormRadio
													id="statusId"
													name="statusId"
													label="Status:"
													:labelCols="mode === 'new' ? 3 : 3"
													:namespace="namespace"
													v-if="isAuthorized('Issue', 'update')"
													fieldName="statusName"
													optionsField="itemOptions"
													text-field="statusName"
													value-field="statusId"
													v-model="form.statusId"
													:state="getFieldState('statusId')"
													@input="$v.form.statusId.$touch()"
													@g3Change="optionFieldChange"
													:error="$v.form.statusId.$error"
													errorMsg="Status is required"
													@blur="$v.form.statusId.$touch()"
													buttons
													buttonVariant="outline-info"
													compact
													size="sm"
												/>
												<!-- Status - read only -->
												<BaseFormInput
													id="status"
													name="statusId"
													label="Status:"
													:labelCols="mode === 'new' ? 3 : 3"
													v-if="!isAuthorized('Issue', 'update')"
													v-model="form.statusId"
													:state="getFieldState('statusId')"
													:readonly="!isAuthorized('Issue', 'update')"
													size="sm"
												/>
												<!-- Priority - Select -->
												<BaseFormSelect
													id="priorityId"
													name="priorityId"
													label="Priority:"
													:labelCols="mode === 'new' ? 3 : 3"
													:namespace="namespace"
													fieldName="priorityName"
													optionsField="itemOptions"
													text-field="priorityName"
													value-field="priorityId"
													v-model="form.priorityId"
													:state="getFieldState('priorityId')"
													@input="$v.form.priorityId.$touch()"
													@g3Change="optionFieldChange"
													:disabled="!isAuthorized('Issue', 'update')"
													:error="$v.form.priorityId.$error"
													errorMsg="Priority is required"
													@blur="$v.form.priorityId.$touch()"
													compact
													size="sm"
												/>
												<!-- Priority - Radio -->
												<BaseFormRadio
													id="priority"
													name="priorityId"
													label=""
													:labelCols="mode === 'new' ? 0 : 0"
													:namespace="namespace"
													v-if="false"
													visibility="hidden"
													fieldName="priorityName"
													optionsField="itemOptions"
													v-model="form.priorityId"
													:state="getFieldState('priorityId')"
													@input="$v.form.priorityId.$touch()"
													@g3Change="optionFieldChange"
													:error="$v.form.priorityId.$error"
													errorMsg="Priority is required"
													@blur="$v.form.priorityId.$touch()"
													buttons
													buttonVariant="outline-info"
													size="sm"
												/>
												<!-- Priority - read only -->
												<BaseFormInput
													id="priority"
													name="priorityId"
													label="Prioirty:"
													:labelCols="mode === 'new' ? 3 : 3"
													v-if="!isAuthorized('Issue', 'update')"
													v-model="form.priorityId"
													:state="getFieldState('priorityId')"
													:readonly="!isAuthorized('Issue', 'update')"
													size="sm"
												/>
												<!-- Type -->
												<BaseFormSelect
													id="typeId"
													name="typeId"
													label="Type:"
													:labelCols="mode === 'new' ? 3 : 3"
													:namespace="namespace"
													fieldName="typeName"
													text-field="typeName"
													value-field="typeId"
													optionsField="itemOptions"
													v-model="form.typeId"
													:state="getFieldState('typeId')"
													@input="$v.form.typeId.$touch()"
													@g3Change="optionFieldChange"
													:disabled="!isAuthorized('Issue', 'update')"
													:error="$v.form.typeId.$error"
													errorMsg="Type is required"
													@blur="$v.form.typeId.$touch()"
													compact
													size="sm"
												/>
												<!-- Assignee -->
												<BaseFormSelect
													id="assigneeId"
													name="assigneeId"
													label="Assignee:"
													:labelCols="mode === 'new' ? 3 : 3"
													:namespace="namespace"
													text-field="displayName"
													value-field="assigneeId"
													fieldName="assigneeFamiliarName"
													optionsField="itemOptions"
													v-model="form.assigneeId"
													:state="getFieldState('assigneeId')"
													@input="$v.form.assigneeId.$touch()"
													@g3Change="optionFieldChange"
													:disabled="!isAuthorized('Issue', 'update')"
													:error="$v.form.assigneeId.$error"
													errorMsg="Assignee is required"
													@blur="$v.form.assigneeId.$touch()"
													compact
													size="sm"
												/>
												<!-- Action -->
												<BaseFormSelect
													id="actionId"
													name="actionId"
													label="Action:"
													:labelCols="mode === 'new' ? 3 : 3"
													:namespace="namespace"
													fieldName="actionName"
													text-field="actionName"
													value-field="actionId"
													optionsField="itemOptions"
													v-model="form.actionId"
													:state="getFieldState('actionId')"
													@input="$v.form.actionId.$touch()"
													@g3Change="optionFieldChange"
													:disabled="!isAuthorized('Issue', 'update')"
													:error="$v.form.actionId.$error"
													errorMsg="Action is required"
													@blur="$v.form.actionId.$touch()"
													size="sm"
													compact
												/>
												<hr v-if="!itemImage()" />
												<!-- Image -->
												<b-card no-body class="mb-2" v-if="itemImage()">
													<b-card-body>
														<b-container class="image-container">
															<b-img
																:src="itemImage()"
																fluid
																alt="Item image"
																class="mx-auto my-auto"
																height="100"
															></b-img>
														</b-container>
													</b-card-body>
												</b-card>
												<!-- Product description -->
												<div class="pb-3 mt-1">
													<span class="text-muted small" v-if="form.asin">
														<b-link
															id="amazon-link"
															target="_blank"
															:href="`https://www.amazon.com/dp/${form.asin}/`"
															rel="noopener noreferrer"
														>
															{{ `${form.asin}: ` }}
														</b-link>
														<b-tooltip target="amazon-link"
															>Click to see Amazon listing</b-tooltip
														>
													</span>
													<span>
														<i class="small">{{ form.productDescription }}</i>
													</span>
												</div>
												<!-- Issue Qty -->
												<BaseFormSpinButton
													id="issueQty2"
													label="Issue qty:"
													ref="focusMe"
													:labelCols="mode === 'new' ? 3 : 3"
													:inline="true"
													:autofocus="true"
													:min="0"
													:max="99"
													size="sm"
													:readonly="!isAuthorized('Issue', 'update')"
													v-model="form.issueQty"
													:state="getFieldState('issueQty')"
													@input="$v.form.issueQty.$touch()"
													:error="$v.form.issueQty.$error"
													errorMsg="Error on Issue qty"
													compact
													class="mb-1"
												/>
											</b-col>
										</b-form-row>
									</template>
								</b-tab>
								<!-- Other Info TAB ************************* -->
								<b-tab lazy class="mt-0 pb-2 bg-light" title="Other Info">
									<template>
										<!-- First row -->
										<b-form-row>
											<!-- Left column -->
											<b-col cols="7" class="mr-1">
												<!-- Originator -->
												<BaseFormSelect
													id="originator"
													name="originator"
													label="Originator:"
													:labelCols="mode === 'new' ? 4 : 4"
													:namespace="namespace"
													fieldName="originator"
													optionsField="itemOptions"
													v-model="form.originator"
													:disabled="!isAuthorized('Issue', 'update')"
													:error="$v.form.originator.$error"
													errorMsg="Originator is required"
													:state="getFieldState('originator')"
													@input="$v.form.originator.$touch()"
													@blur="$v.form.originator.$touch()"
													compact
													size="sm"
												/>
												<!-- Client Facility -->
												<BaseFormSelect
													id="clientFacilityName"
													name="clientFacilityName"
													label="Client facility:"
													:labelCols="mode === 'new' ? 4 : 4"
													:namespace="namespace"
													fieldName="clientFacilityName"
													text-field="clientFacilityName"
													value-field="clientFacilityId"
													optionsField="itemOptions"
													v-model="form.clientFacilityId"
													:disabled="!isAuthorized('Issue', 'update')"
													:error="$v.form.clientFacilityId.$error"
													errorMsg="Client facility is required"
													:state="getFieldState('clientFacilityId')"
													@blur="$v.form.clientFacilityId.$touch()"
													@input="$v.form.clientFacilityId.$touch()"
													@g3Change="optionFieldChange"
													compact
													size="sm"
												/>
												<!-- Processor Facility -->
												<BaseFormSelect
													id="processorFacilityName"
													name="processorFacilityName"
													label="Processor facility:"
													:labelCols="mode === 'new' ? 4 : 4"
													:namespace="namespace"
													fieldName="processorFacilityName"
													value-field="processorFacilityId"
													text-field="processorFacilityName"
													optionsField="itemOptions"
													v-model="form.processorFacilityId"
													:disabled="!isAuthorized('Issue', 'update')"
													:error="$v.form.processorFacilityId.$error"
													:state="getFieldState('processorFacilityId')"
													@blur="$v.form.processorFacilityId.$touch()"
													@input="$v.form.processorFacilityId.$touch()"
													@g3Change="optionFieldChange"
													size="sm"
												/>
											</b-col>
											<!-- Right column -->
											<b-col class="text-right">
											</b-col>
										</b-form-row>
										<hr />
										<!-- Second row - Single column -->
										<b-form-row>
											<b-col>
												<IssuePurchasedItemDetail :issue="currentItem" />
											</b-col>
										</b-form-row>
									</template>
								</b-tab>
								<!-- Attach files -->
								<b-tab class="mt-0 pb-2 bg-light">
									<template slot="title">
										Attachments
										<span v-if="currentItem.hasAttachments">
											<i :class="'fas fa-paperclip'"></i>
										</span>
									</template>
									<div class="container">
										<b-row>
											<b-col>
												<BaseDropzone
													:recId="form.id"
													:namespace="namespace"
													ref="dropzone_ref"
													@attachmentsChanged="setAttachmentsChanged"
												/>
											</b-col>
										</b-row>
									</div>
								</b-tab>
								<!-- DETAIL/HISTORY -->
								<b-tab lazy title="Detail/History	" class="bg-light">
									<template>
										<div class="m-3">
											<b-form-row>
												<h4 class="pb-0 pl-1 history-heading">Record Detail</h4>
											</b-form-row>
											<b-form-row>
												<b-col cols="7">
													<div>
														<!-- Top-left controls -->
														<BaseFormInput
															v-for="item in topLeft"
															:key="item.id"
															:id="item.id"
															:label="item.label"
															:labelCols="4"
															plaintext
															labelClass="text-muted"
															size="sm"
															readonly
															compact
															:value="item.value"
														/>
													</div>
												</b-col>
												<b-col>
													<div>
														<!-- Top-right controls -->
														<BaseFormInput
															v-for="item in topRight"
															:key="item.id"
															:id="item.id"
															:label="item.label"
															:labelCols="5"
															labelAlign="right"
															plaintext
															labelClass="text-muted"
															size="sm"
															readonly
															compact
															:value="item.value"
														/>
													</div>
												</b-col>
											</b-form-row>
											<hr />
											<!-- History -->
											<b-form-row>
												<h4 class="pb-0 pl-1 history-heading">Record History</h4>
											</b-form-row>
											<div v-if="noHistory" class="no-history">No history to display</div>
											<div v-if="!noHistory">
												<div class="history-scrollable-list">
													<b-list-group
														v-for="(hx, index) in currentItem.logs"
														:key="index"
														class="history-item p-0"
													>
														<b-list-group-item class="list-item px-3 py-1">
															<BaseLogItem :item="hx" class="log-item" />
														</b-list-group-item>
													</b-list-group>
												</div>
											</div>
										</div>
									</template>
								</b-tab>
							</b-tabs>
						</b-card>
						<!-- Card footer slot -->
						<template #footer>
							<div class="d-flex justify-content-between">
								<b-button
									class="mr-3"
									variant="success"
									@click="onComplete"
									:disabled="mode !== 'update' || form.statusId === 2"
								>
									Complete Issue
								</b-button>
								<div class="d-flex">
									<b-button
										class="mr-3"
										variant="secondary"
										@click="formCancel"
										>{{ isFormDirty ? "Cancel" : "Close" }}</b-button
									>
									<b-button
										type="submit"
										variant="primary"
										:disabled="
											!isFormDirty && mode !== 'new' && !attachmentsChanged
										"
										v-if="isAuthorized('Issue', 'update')"
										>Save</b-button
									>
								</div>
							</div>
						</template>
					</b-card>
				</b-form>
			</b-overlay>
		</div>
	</b-container>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import {
	handleBeforeDestroy,
	handleCreated,
	handleFormCancel,
	handleFormSave,
	handleItemImage,
	handleSyncOptionName,
	handleValidateState
} from "../../utils/component-utils";
import IssuePurchasedItemDetail from "./IssuePurchasedItemDetail.vue";
import IssueComments from "./IssueComments.vue"

export default {
	components: {
		IssuePurchasedItemDetail, IssueComments
	},
	name: 'IssueItem',
	props: {
		namespace: { type: String, default: "Issue2" },
		itemId: { type: Number, default: -1 },
	},
	data() {
		return {
			mode: { type: String, default: "New" },
			isModalVisible: false,
			isDataLoaded: false,
			form: {
				id: this.$props.itemId,
				actionId: "",
				actionName:"",
				asin: "",
				assigneeId: "",
				assigneeFamiliarName: "",
				assigneeFullName: "",
				assigneeUserId: "",
				clientFacilityId: "",
				clientFacilityName: "",
				clientId: "",
				clientName: "",
				createdBy: "",
				createdOn: "",
				imageUrl: "",
				issueReason: "",
				issueQty: 0,
				lastUpdatedBy: "",
				originator: "",
				priorityId: 2,
				priorityName: "",
				processorFacilityId: "",
				processorFacilityName: "",
				processorName: "",
				productDescription: "",
				productId: "",
				purchasedItemRecordId: 0,
				statusId: "Open",
				statusName:"",
				tenantId: "",
				ts: "",
				typeId: "",
				typeName:"",
				unread: false,
			},
			attachmentsChanged: false,
			confirmingChanges: false,
			childDirty: false,
			sourcePathName: ""
		}
	},
	validations: {
		form: {
			actionId: {},
			assigneeId: { required },
			asin: {},
			attachments: [],
			clientFacilityId: { required },
			issueQty: {},
			issueReason: { required },
			originator: { required },
			priorityId: { required },
			processorFacilityId: {},
			statusId: { required },
			typeId: { required },
			unread: {},
		},
		focusedElement: "",
	},
	async created() {
		try {
			await handleCreated(this, document);
			// Reset form validation
			this.$nextTick(() => {
				this.$v.$reset();
			})
			this.isDataLoaded = true;
		} catch (error) {
			console.error("Error during component creation", error)
		}
	},
	mounted() {
	},
	computed: {
		...mapGetters("Session", [
			"isLoading",
			"showLoading",
			"isAuthorized",
			"user",
			"authToken",
		]),
		isFormDirty() {
			return this.childDirty || this.$v.$anyDirty;
		},
		// Store to component data binding
		currentItem() {
			return this.$store.getters[`${this.namespace}/currentItem`];
		},
		// *********************************************
		//          Entity-specific computed
		// *********************************************
		comments() {
			return this.$store.getters[`${this.namespace}/comments`];
		},
		// Detail/history
		topLeft() {
				return [
						{ id: 'id', label: 'Record id:', value: this.form.id },
						{ id: 'processorName', label: 'Processor name:', value: this.form.processorName },
						{ id: 'clientName', label: 'Client name:', value: this.form.clientName },
						{ id: 'tenantId', label: 'Tenant id:', value: this.form.tenantId },
						{ id: 'clientId', label: 'Client id:', value: this.form.clientId },
						{ id: 'unread', label: 'Unread by assignee:', value: this.convertNumberToBoolean(this.form.unread) },
				]
		},
		// Detail/history
		topRight() {
				return [
						{ id: 'assigneeFullName', label: 'Assignee name:', value: this.form.assigneeFullName },
						{ id: 'assigneeUserId', label: 'Assignee user id:', value: this.form.assigneeUserId },
						{ id: 'createdOn', label: 'Created on:', value: new Date(this.form.createdOn).toLocaleString() },
						{ id: 'createdBy', label: 'Created by:', value: this.form.createdBy },
						{ id: 'lastEditedOn', label: 'Last updated on:', value: new Date(this.form.ts).toLocaleString() },
						{ id: 'lastUpdatedBy', label: 'Last updated by:', value: this.form.lastUpdatedBy },
				]
		},
		noHistory() {
			if (!this.isDataLoaded || !this.currentItem) return true;
			if (this.currentItem && this.currentItem.logs && this.currentItem.logs.length > 0) {
				return false;
			} else {
				return true;
			}
		}
	},
	methods: {
		// *********************************************
		//          Entity-specific Methods
		// *********************************************
		handleCommentDirty() {
			// Mark form as dirty
			console.log("IssueItem.commentDirty")
			// update store
       this.$v.form.$touch();
		},
		handleCommentBlurred(data) {
			console.log("IssueItem.handleCommentBlurred")
			this.$store.dispatch(`${this.namespace}/updateComment`, data.comment);
		},
    handleCommentDeleted() {
			console.log("IsueItem.commentDeleted")
      this.$v.form.$touch();
    },
		convertNumberToBoolean(value) {
				return value === 1 ? "true": "false"
		},
		headerDescription() {
			const desc = this.form.issueReason?.length > 75
				? this.form.issueReason?.substring(0, 75) + "..."
				: this.form.issueReason
			return desc?.replace(".", "").replace(",", "").replace("!", "").replace(":", "")
		},
		async onComplete() {
			// Ensure Closed status is id 2 //TODO: Need to ensure this and possibly allow for different status=closed
			this.form.statusId = 2
			this.form.statusName = "Closed"
			this.formSave();
		},
		optionFieldChange({ field, value }) {
			// Option dropdowns: Sync id and name
      if (field === 'typeId') {
				this.optionFieldSync(value, field, 'typeName', 'itemOptions');
			} else if (field === "statusId"){
        this.optionFieldSync(value, field, 'statusName', 'itemOptions');
			} else if (field === "priorityId"){
        this.optionFieldSync(value, field, 'priorityName', 'itemOptions');
			} else if (field === "actionId"){
        this.optionFieldSync(value, field, 'actionName', 'itemOptions');
			} else if (field === "assigneeId"){
        this.optionFieldSync(value, field, 'assigneeFamiliarName', 'itemOptions');
			} else if (field === "processorFacilityId"){
        this.optionFieldSync(value, field, 'processorFacilityName', 'itemOptions');
			} else if (field === "clientFacilityId"){
        this.optionFieldSync(value, field, 'clientFacilityName', 'itemOptions');
			}
    },
		optionFieldSync(value, valueField, textField, optionsField) {
			handleSyncOptionName({ thisObj: this, value, valueField, textField, optionsField })
		},
		setAttachmentsChanged() {
			this.attachmentsChanged = true;
			this.$v.form.attachments.$touch();
		},
		async unreadFlagClear() {
			if (
				this.mode === "update" &&
				this.currentItem.unread &&
				this.currentItem.assigneeFamiliarName === this.user.familiarName
			) {
				this.$store.dispatch(`${this.namespace}/currentItemFieldSet`, {
					key: "unread",
					value: 0,
				});
				this.$store.dispatch(`${this.namespace}/itemSaveUnreadFlag`, this.form.id);
			}
		},
		async unreadFlagSet() {
			if (
				this.mode === "update" &&
				!this.currentItem.unread &&
				this.currentItem.assigneeFamiliarName !== this.user.familiarName
			) {
				this.form.unread = 1;
			}
		},
		// *********************************************
		//          Standard Item Methods
		// *********************************************
		beforeDestroy() {
			handleBeforeDestroy(this, document)
		},
		detailModalUpdate(val) {
      this.isModalVisible = val; // Sync parent visibility with child
    },
    detailModalShow() {
      this.isModalVisible = true;
    },
    focusChanged(event) {
      const el = event.target;
      this.focusedElement = el.id;
    },
		getFieldState(data) {
			return handleValidateState(this, data)
		},
		itemImage() {
			return handleItemImage(this)
		},

		// *********************************************
		//          Item validate/close/cancel Methods
		// *********************************************
		async formCancel() {
			await this.unreadFlagClear();
			await handleFormCancel(this)
		},
		async formSave() {
			await handleFormSave(this);
			this.unreadFlagSet()
		},
	},
};


</script>

<style scoped>
.comments-scrollable-list {
	max-height: 550px;
	padding: 10px;
	overflow-x: hidden;
	overflow-y: auto;
}
.comments-heading {
	margin-top: 30px;
	font-weight: 600;
	font-size: .9rem;
	color: #242323;
	text-transform: uppercase;
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
}
}

</style>
