// *************************************************
// Utility functions shared by components
// *************************************************

//import { _ } from "core-js";
import { titleize } from "../utils/utils.js";
import defaultImage from "../assets/image-not-available-250.png";

export function defaultIssueAssignee(route, role, familiarName) {
  // Issue default assignee rules
  // Hard-coding familiar names for now...
  if (route.includes("qpissue") && role.includes("QikPrep")) return "Regina";
  if (route.includes("qpissue") && !role.includes("QikPrep")) return "Arturo";
  if (route.includes("PurchasedItem") && role.includes("QikPrep"))
    return "Regina";
  if (route.includes("PurchasedItem") && !role.includes("QikPrep"))
    return "Arturo";
  return familiarName;
}

export function handleRouterPushError(error) {
  // Ignore the vuex err regarding  navigating to the page they are already on.
  if (
    error.name === "NavigationDuplicated" &&
    error.message.includes("Avoided redundant navigation to current location")
  ) {
    // Suppress this error
    console.log("Suppressed router push error: ", error.message);
    return;
  } else if (
    error.message.includes("Navigation aborted from") &&
    error.message.includes("via a navigation guard")
  ) {
    // Suppress error
    console.log("Suppressed router push error: ", error.message);
    return;
  } else {
    // Unknown error
    console.error("Routing error occurred: ", error);
  }
}

export function setSubNavText(funcSetText, funcSetAccent, mainNavText) {
  // Set subnav title and color
  if (mainNavText === "Issue") {
    // Issue: Red
    funcSetText("Issue");
    funcSetAccent("255, 0, 0");
    document.title = "g3tools - Issues";
  } else if (mainNavText === "Online Arbitrage") {
    // OAR: Green
    funcSetText("OAR - Online Arbitrage");
    funcSetAccent("0, 153, 51");
    document.title = "g3tools - Online Arbitrage";
  } else if (mainNavText === "Product") {
    // Product: Purple
    funcSetText("Product");
    funcSetAccent("127, 44, 209");
    document.title = "g3tools - Product";
  } else if (mainNavText === "Amazon") {
    // Amazon: Orange
    funcSetText("Amazon");
    funcSetAccent("197, 137, 48");
    document.title = "g3tools - Amazon";
  } else if (mainNavText === "Purchasing") {
    // Purchasing: Blue
    funcSetText("Purchasing");
    funcSetAccent("7,144,212");
    document.title = "g3tools - Purchasing";
  } else if (mainNavText === "Inventory") {
    // Purchasing: Violet
    funcSetText("Inventory");
    funcSetAccent("209, 44, 193");
    document.title = "g3tools - Inventory";
  }
}
// *********************************************
//          Standard Item Methods
// *********************************************
export function handleBeforeDestroy(thisObj, document) {
  document.removeEventListener("focusin", thisObj.focusChanged);
}

// Created life cycle hook for component
export async function handleCreated(thisObj, document) {
  const id = thisObj.$props.itemId;
  thisObj.sourcePathName = thisObj.$route.params.sourcePathName;
  // Track which element has focus
  document.addEventListener("focusin", thisObj.focusChanged);
  thisObj.mode = id > -1 ? "update" : "new";
  // Initialize and sync current item with items[] object from list
  await thisObj.$store.dispatch(`${thisObj.namespace}/basePrepareCurrentItem`);
  await thisObj.$store.dispatch(
    `${thisObj.namespace}/baseSyncItemFromList`,
    id
  );
  await handleLoadForm(thisObj);
  if (thisObj.$v) {
    thisObj.$v.$reset();
  }
  // Get record if not new
  if (thisObj.mode !== "new") {
    // Retrieve item from db and assign into (refresh) current item
    await thisObj.$store.dispatch(`${thisObj.namespace}/fetchItem`, id);
    if (thisObj.$v) {
      thisObj.$v.$reset();
    }
  } else {
    // New item
    await thisObj.$store.dispatch(`${thisObj.namespace}/newItem`);
  }
  // Load currentItem into form object
  await handleLoadForm(thisObj);
}

export function handleItemImage(thisObj) {
  return thisObj.currentItem.imageUrl
    ? thisObj.currentItem.imageUrl?.replace("_SL40", "_SL150")
    : defaultImage;
}

export async function handleLoadForm(thisObj) {
  // Load form data from currentItem
  for (let key in thisObj?.form) {
    const currData = thisObj?.currentItem[key];
    thisObj.form[key] = currData;
  }
}

export function handleSyncOptionName({
  thisObj,
  value,
  valueField,
  textField,
  optionsField,
}) {
  // Find filter object matching field
  const filter = thisObj.$store.getters[`${thisObj.namespace}/filters`]?.find(
    (filter) => filter.idField === valueField
  );
  const selectedOption = filter[optionsField]?.find(
    (option) => option[valueField] === value
  );
  if (selectedOption) {
    thisObj.form[textField] = selectedOption[textField];
  }
}

export function handleValidateState(thisObj, data) {
  const field = thisObj.$v.form[data];
  if (!field) return null;
  return field.$dirty && field.$error ? false : field.$dirty ? true : null;
}

// Changes store values (component to store data binding)
export function handleUpdateLocalValue(data, thisObj) {
  if (!data.field) return;
  let { field, value } = data;
  // Nullify dates
  if (field.toLowerCase().includes("date") && value === "") {
    value = null;
  }
  // Update store
  thisObj.$store.dispatch(`${thisObj.namespace}/currentItemFieldSet`, {
    key: field,
    value: value,
  });
  // Update local form object
  if (thisObj.form[field] !== undefined) {
    thisObj.form[field] = value;
  }
  // Update validate object
  if (thisObj.$v.form[field]) {
    thisObj.$v.form[field].$touch();
  }
}

// *********************************************
//          Item validate/close/cancel Methods
// *********************************************
export async function handleFormCancel(thisObj) {
  try {
    if (thisObj.$v.form.$anyDirty) {
      // Dirty: Confirm before close
      thisObj.confirmingChanges = true;
      const reply = await handleConfirmChanges(thisObj);
      thisObj.confirmingChanges = false;
      if (reply === null) return;
      else if (reply) {
        // Yes, save before leaving
        await thisObj.formSave();
      } else {
        // No. Don't save before cancel
        thisObj.$emit("cancelModal");
      }
    } else {
      thisObj.$emit("cancelModal");
    }
  } catch (err) {
    console.error("Error in formCancel: ", err);
    throw err;
  }
}

export async function handleFormSave(thisObj) {
  try {
    if (thisObj.confirmingChanges) return;
    thisObj.$v.$touch();
    if (thisObj.$v.form.$anyError) {
      handleShowAlert(thisObj);
      return;
    }
    // Save item
    await handleSave(thisObj);
    thisObj.$emit("cancelModal");
  } catch (err) {
    console.error("Error in handleFormSave: ", err);
    throw err;
  }
}

export function handleShowAlert(thisObj) {
  try {
    const msg = "Please fix errors before saving";
    thisObj.$store.dispatch(
      "Notification/alertErrorAdd",
      { message: msg, show: 7 },
      { root: true }
    );
    return false;
  } catch (err) {
    console.error("Error in handleShowAlert: ", err);
    throw err;
  }
}


// Submit item changes
// export async function handleOnSubmit(thisObj) {
//   // Alert if any validation errors
//   // Must touch select fields here to catch validation on new item
//   thisObj.$v.$touch();
//   if (thisObj.$v.$anyError) {
//     const msg = "Please fix errors before saving.";
//     thisObj.$store.dispatch(
//       "Notification/alertErrorAdd",
//       { message: msg, show: 7 },
//       { root: true }
//     );
//     return false;
//   }
//   if (!thisObj.$v.$anyError) {
//     try {
//       // Save existing item
//       if (!(await thisObj.save())) return;
//       // Route back to list
//       thisObj.$router
//         .push({
//           name: thisObj.sourcePathName,
//         })
//         .catch((err) => {
//           handleRouterPushError(err);
//         });
//     } catch (error) {
//       console.log(error);
//     }
//   }
// }

// Save item
export async function handleSave(thisObj) {
  if (thisObj.mode === "new") {
    // Create item
    if (
      !(await thisObj.$store.dispatch(
        `${thisObj.namespace}/itemCreate`,
        thisObj.form
      ))
    ) {
      return false;
    }
  } else {
    // Save existing item
    if (
      !(await thisObj.$store.dispatch(
        `${thisObj.namespace}/itemSave`,
        thisObj.form
      ))
    ) {
      return false;
    }
  }
  // Reset validations
  thisObj.$v.$reset();
  return true;
}

// Cancel item
//export async function handleOnCancel(props) {
// export async function handleOnCancel() {
//   // const { thisObj, forceReload, tabIndex } = props;
//   try {
//     // console.log(
//     //   "In component-utils.handleOnCancel - thisObj.sourcePathName: ",
//     //   thisObj.sourcePathName
//     // );
//     // await thisObj.$router.push({
//     //   name: thisObj.sourcePathName,
//     //   params: { reload: forceReload || false, tabIndex: tabIndex || 0 },
//     // });
//   } catch (err) {
//     handleRouterPushError(err);
//   }
// }

// Before leaving item
//export async function handleBeforeRouteLeave(to, from, next, thisObj) {
export async function handleBeforeRouteLeave() {
  // try {
  //   const reply = await thisObj.confirmChanges();
  //   if (reply === null) {
  //     // Cancel the cancel. Do nothing and return to record.
  //     next(false);
  //     return;
  //   } else if (reply) {
  //     // Yes. Save changes. User saved changes; redo route with reload
  //     next(false);
  //     // Causing error
  //     await thisObj.$router
  //       .push({
  //         name: thisObj.sourcePathName,
  //         params: { reload: true },
  //       })
  //       .catch((err) => {
  //         handleRouterPushError(err);
  //       });
  //     return;
  //   }
  //   next();
  // } catch (err) {
  //   console.error("Error in beforeRouteLeave in Issue Item record: ", err);
  // }
}

// Confirm changes
export async function handleConfirmChanges(thisObj) {
  // Confirm cancel
  const confirmOptions = {
    title: "Please Confirm",
    okTitle: "Yes",
    cancelTitle: "No",
    cancelVariant: "light",
    footerClass: "p-2",
    bodyClass: "p-2",
    hideHeaderClose: false,
    centered: true,
  };
  const reply = await thisObj.$bvModal.msgBoxConfirm(
    "Do you want to save changes?",
    confirmOptions
  );
  // Cancel the cancel
  if (reply === null) return null;
  else if (reply) {
    // Yes, save before leaving
    return true;
  } else {
    // No. Don't save before cancel
    return false;
  }
}

// Confirm changes
// export async function handleConfirmChanges(thisObj) {
//   if (thisObj.$v.$anyDirty) {
//     // Confirm cancel
//     const confirmOptions = {
//       title: "Please Confirm",
//       okTitle: "Yes",
//       cancelTitle: "No",
//       cancelVariant: "light",
//       footerClass: "p-2",
//       hideHeaderClose: false,
//       centered: true,
//     };
//     const reply = await thisObj.$bvModal.msgBoxConfirm(
//       "Do you want to save changes to this item?",
//       confirmOptions
//     );
//     // Cancel the cancel
//     if (reply === null) return null;
//     else if (reply) {
//       // Yes, save before leaving
//       await thisObj.save();
//       return true;
//     } else {
//       // No. Don't save before cancel
//       await cancelAttachmentChanges(thisObj);
//       return false;
//     }
//   }
// }
// // Canceling record (i.e., issue) update. If user added or removed attachments,
// // need to cancel these changes, meaning add or remove images from S3
// async function cancelAttachmentChanges(thisObj) {
//   if (!thisObj) return;
//   if (!thisObj.currentItem) return;
//   if (!thisObj.currentItem?.attachments) return;
//   if (!thisObj.$refs.dropzone_ref) return;
//   if (!thisObj.$refs.dropzone_ref.filesAdded) return;
//   if (
//     !thisObj.$refs.dropzone_ref.filesAdded &&
//     !thisObj.$refs.dropzone_ref.filesDeleted
//   )
//     return;
//   const filesAdded = thisObj.$refs.dropzone_ref.filesAdded;
//   const filesDeleted = thisObj.$refs.dropzone_ref.filesDeleted;
//   if (_.isEqual(filesAdded, filesDeleted)) return;
//   // We have attachments, dropzone, and some attachment activity this session:
//   const dz = thisObj.$refs.dropzone_ref;
//   if (filesAdded && filesAdded?.length > 0) {
//     // Files added this session; remove thumbnail and full file from s3
//     _.forEach(filesAdded, async (file) => {
//       // Permanently remove files from s3
//       //console.log("Delete file ", file);
//       await dz.deleteFilePermanent(file);
//     });
//   }
//   if (filesDeleted && filesDeleted?.length > 0) {
//     // Files removed this session; add back in s3 (undelete)
//     _.forEach(filesDeleted, async (file) => {
//       // Permanently remove files from s3
//       //console.log("restore file ", file);
//       await dz.restoreFile(file);
//     });
//   }
// }

// export function formatRawHtml(obj) {
//   // convert json object to formatted text for display
//   let txtDisplay = "";
//   const keyStyle = `style="font-weight: 600; color:#000;"`;
//   for (const key of Object.keys(obj)) {
//     if (obj[key] === null) {
//       const proper = titleize(key);
//       txtDisplay += `<span ${keyStyle}>${proper}</span>: NULL<br>`;
//     } else if (
//       typeof obj[key] === "object" ||
//       obj[key].toString().startsWith('{"')
//     ) {
//       // Format a JSON field
//       const jsonField = JSON.parse(obj[key]);
//       let txtField = `<span ${keyStyle}>${titleize(key)}</span>: `;
//       for (const key of Object.keys(jsonField)) {
//         //const proper = titleize(key);
//         txtField += `${key}: ${jsonField[key]}; `;
//       }
//       txtDisplay += `${txtField}<br>`;
//     } else {
//       const proper = titleize(key);
//       txtDisplay += `<span ${keyStyle}>${proper}</span>: ${obj[key]}<br>`;
//     }
//   }
//   return txtDisplay;
// }

export function formatRawHtml(obj) {
  let txtDisplay = "";
  const keyStyle = `style="font-weight: 600; color:#000;"`; // Style for bold keys
  //const parsedObj = JSON.parse(obj);
  for (const key of Object.keys(obj)) {
    const properKey = titleize(key); // Capitalizes the key properly
    let value = obj[key].toString(); // Convert value to string
    txtDisplay += `<span ${keyStyle}>${properKey}</span>: ${value}<br>`;
  }
  return txtDisplay;
}

// Helper function to capitalize the first letter of each word
// function titleize(str) {
//   return str
//     .replace(/_/g, " ") // Replace underscores with spaces
//     .replace(
//       /\w\S*/g,
//       (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
//     ); // Capitalize first letter
// }

export function formatCrudAction(action) {
  if (action.toLowerCase() === "update") return "action-update";
  else if (action.toLowerCase() === "delete") return "action-delete";
  return "action-insert";
}

export function formatCurrency(amt, decimalDigits) {
  const fmtr = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: decimalDigits !== undefined ? decimalDigits : 2,
    maximumFractionDigits: decimalDigits !== undefined ? decimalDigits : 2,
  });
  return fmtr.format(amt);
}

export function formatPercent(value) {
  if (value === undefined) return;
  const useValue = value.toString().replace("%", "");
  if (value === "0%") return value;
  if (value === "0") return "0%";
  const pctValue = (useValue * 100).toFixed(0);
  return pctValue ? `${pctValue}%` : "0%";
}
