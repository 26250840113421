// store/modules/issue.store.js
import * as baseStore from "./base2.store";
//import * as commentStore from "./issue-comments.store";
import * as issueModel from "../../views/issue/issue-model.js";
import config from "../../../config.js";

const initialState = () => {
  return {
    ...baseStore.state,
    comments: [],
    commentsCopy: [],
    attachments: [],
    attachmentsCopy: [],
    list: {
      allowInsert: false,
      currentPage: 1,
      documentTitle: "g3tools - Processing Issues",
      fields: [],
      imageField: "imageUrl",
      keyField: "",
      listName: "Processing Issues - All Open",
      orderClause: "",
      pageSize: 50,
      role: "Issue",
      routeName: "Issue",
      searchText: "",
      selectedItems: 0,
      selectMode: "single", // multi, single, range
      selectRowShowsDetail: true,
      showAdd: true,
      showDelete: false,
      showOtherActions: false,
      sortBy: "ts",
      sortOrder: "DESC",
      totalRows: 0,
    },
    entityDescription: "Unknown entity type",
    subNavTitle: "Unknown Title",
    sidebarHidden: false,
    filters: issueModel.getFilters(config.SCOPES.CLIENT),
  };
};

const state = initialState();

// Getters specific to issue.store
const getters = {
  ...baseStore.getters,
  //...commentStore.getters,
  currentItem: (state) => state.currentItem,
  isItemLoaded: (state) => {
    return !!state.currentItem;
  },
  list: (state) => state.list,
  filters: (state) => state.filters,
  items: (state) => state.items,
  comments: (state) => state.comments,
  attachments: (state) => state.attachments,
};

const actions = {
  ...baseStore.actions,
  //...commentStore.actions,

  async initializeStore({ commit, rootState }) {
    // Set fields and filters based on scope
    const useScope = rootState.Session.scope;
    const scopedFields = issueModel.getFields(useScope);
    commit("SET_FIELDS", scopedFields);
    const scopedFilters = issueModel.getFilters(useScope);
    commit("SET_FILTERS", scopedFilters);
  },
  // Update existing comment
  updateComment({ commit, state }, updatedComment) {
    console.log("store udpateComment, udpatedComment: ", updatedComment);
    const existingComment = state.comments.find(
      (comment) => comment.id === updatedComment.id
    );
    if (existingComment) {
      commit("COMMENT_SET", updatedComment);
    } else {
      console.error(`Comment with ID ${updatedComment.id} does not exist.`);
    }
  },
  // Add new comment to record
  async addComment({ commit, state, rootState }) {
    // If already has a new comment, skip
    if (state.currentItem?.comments?.find((x) => x.id === -1)) return;
    const issueId = state.currentItem.id;
    const newComment = {
      id: -1,
      issueId,
      createdById: rootState.Session.user.id,
      comment: "",
      lastUpdatedById: rootState.Session.user.id,
    };
    commit("COMMENT_NEW_SET", newComment);
  },

  async clearFilters({ state, dispatch }, { fetchOnStart }) {
    // Set filters based on route
    await dispatch("baseClearFilters", {
      endpoint: "/issue",
      fetchOnStart,
      state,
    });
    await dispatch("baseFetchAll", { endpoint: "/issue", state });
  },
  async clearFiltersOnly({ state, commit, dispatch }) {
    // Set filters based on route
    await dispatch("dynamicFilters");
    await baseStore.actions.baseClearFiltersOnly({ state, commit, dispatch });
  },
  dynamicFilters(context) {
    issueModel.dynamicFilters(state, context);
  },

  async newItem({ dispatch, commit, rootState }) {
    commit("ISSUE_ITEM_RESET");
    await dispatch("basePrepareCurrentItem");
    const newIssue = issueModel.defaultIssue(rootState.Session.user);
    commit("CURRENT_ITEM_SET", newIssue);
    await dispatch("addComment");
  },
  // Use the shared fetchItem action from base.store
  async fetchItem({ dispatch, commit, state }, itemId) {
    commit("ISSUE_ITEM_RESET");
    // Get issue and related comments, attachments, and logs from db
    const response = await dispatch("baseFetchItem", {
      endpoint: "/issue",
      itemId,
      state,
    });
    // Move child data into state
    commit("COMMENT_ITEMS_SET", response?.data?.comments);
    commit("COMMENT_ITEMS_COPY_SET", response?.data?.comments);
    commit("ATTACHMENT_ITEMS_SET", response?.data?.attachments);
    commit("ATTACHMENT_ITEMS_COPY_SET", response?.data?.attachments);
    // Add new comment
    await dispatch("addComment");
  },

  async fetchAll({ dispatch, state }) {
    await dispatch("baseFetchAll", { endpoint: "/issue", state });
  },

  //async itemCreate({ dispatch, state }, item) {},

  async itemSaveUnreadFlag({ dispatch }, id) {
    if (state.currentItem.id !== id) {
      throw new Error("Current item doesn't match expected item id");
    }
    const payload = {
      id: id,
      tenantId: state.currentItem.tenantId,
      clientId: state.currentItem.clientId,
    };
    await dispatch("baseItemSave", {
      endpoint: "/issue/unread",
      record: payload,
    });
  },
  async itemSave({ dispatch, state, commit }, record) {
    commit("CURRENT_ITEM_SET_ASSIGN", record);
    const [recordChanges, changedComments, changedAttachments] =
      await Promise.all([
        dispatch("getCurrentItemChanges"),
        state.comments && state.comments.length > 0
          ? dispatch("getCommentChanges")
          : null,
        state.attachments && state.attachments.length > 0
          ? dispatch("getAttachmentChanges")
          : null,
      ]);
    let updatedRecord = {
      id: record.id,
      tenantId: record.tenantId,
      clientId: record.clientId,
    };
    // Send combined currentItem with changed comments and attachments
    if (recordChanges || changedComments || changedAttachments) {
      if (recordChanges) {
        updatedRecord = { ...updatedRecord, ...recordChanges };
      }
      if (changedComments) {
        updatedRecord.changedComments = changedComments;
      }
      if (changedAttachments) {
        updatedRecord.changedAttachments = changedAttachments;
      }
    }
    const savedRecord = await dispatch("baseItemSaveDirect", {
      endpoint: "/issue",
      record: updatedRecord,
    });
    commit("CURRENT_ITEM_SET_ASSIGN", savedRecord.data);
  },
  async getCurrentItemChanges({ dispatch, state }) {
    return await dispatch("getChanges", {
      current: state.currentItem,
      copy: state.currentItemCopy,
    });
  },
  async getCommentChanges({ dispatch, state }) {
    const deltaChanges = [];
    // Iterate through original comments and commentsCopy
    for (const updatedComment of state.comments) {
      if (updatedComment.id === -1 && updatedComment.comment) {
        // New comment; add to changes
        deltaChanges.push(updatedComment);
      } else {
        // Existing comment
        const copiedComment = state.commentsCopy.find(
          (c) => c.id === updatedComment.id
        );
        if (copiedComment) {
          // Found copied comment. Now compare
          const changedComment = await dispatch("getChanges", {
            current: updatedComment,
            copy: copiedComment,
          });
          if (changedComment) {
            deltaChanges.push(changedComment);
          }
        }
      }
    }
    return deltaChanges;
  },
  async getAttachmentChanges({ dispatch, state }) {
    const deltaChanges = [];
    // Iterate through original attachments and attachmentsCopy
    for (const updatedAttachment of state.attachments) {
      if (updatedAttachment.id === -1) {
        // New comment; add to changes
        deltaChanges.push(updatedAttachment);
      } else {
        // Existing comment
        const copiedAttachment = state.attachmentsCopy.find(
          (c) => c.id === updatedAttachment.id
        );
        if (copiedAttachment) {
          // Found copied comment. Now compare
          const changedAttachment = await dispatch("getChanges", {
            current: updatedAttachment,
            copy: copiedAttachment,
          });
          if (changedAttachment) {
            deltaChanges.push(changedAttachment);
          }
        }
      }
    }
    return deltaChanges;
  },
  // Dropdown filter set
  async setFilterSelectedItems(context, payload) {
    context.commit("FILTER_SELECTED_SET", payload);
  },
};

const mutations = {
  ...baseStore.mutations,
  //...commentStore.mutations,
  ATTACHMENT_ADD(state, data) {
    if (!state.attachments) state.attachments = [];
    state.attachments.push(data);
  },
  ATTACHMENT_REMOVE(state, data) {
    if (!state.attachments) return;
    if (!Array.isArray(state.attachments)) return;
    state.attachments = state.attachments.filter(
      (file) => file.thumbnailKey !== data
    );
  },
  ATTACHMENT_ITEMS_SET(state, data) {
    state.attachments = data;
  },
  ATTACHMENT_CHANGED_ITEMS_SET(state, data) {
    state.currentItem.changedAttachments = data;
  },
  ATTACHMENT_ITEMS_COPY_SET(state, data) {
    state.attachmentsCopy = [...data ];
  },
  HAS_ATTACHMENTS(state, data) {
    state.hasAttachments = data;
  },
  ISSUE_ITEM_RESET(state) {
    state.currentItemCopy = {};
    state.comments = [];
    state.commentsCopy = [];
    state.attachments = [];
    state.attachmentsCopy = [];
  },
  COMMENT_ITEMS_SET(state, data) {
    state.comments = data;
  },
  COMMENT_CHANGED_ITEMS_SET(state, data) {
    state.currentItem.changedComments = data;
  },
  COMMENT_ITEMS_COPY_SET(state, data) {
    state.commentsCopy = [ ...data ];
  },
  COMMENT_SET(state, data) {
    const commentIndex = state.comments.findIndex((x) => x.id === data.id);
    if (commentIndex !== -1) {
      state.comments[commentIndex] = {
        ...state.comments[commentIndex],
        ...data,
      };
    }
  },
  COMMENT_NEW_SET(state, data) {
    state.comments?.unshift(data);
  },
  // CURRENT_ITEM_RESTORE(state) {
  //   state.currentItem.comments = state.comments;
  //   state.currentItem.attachments = state.attachments;
  // },
  // CURRENT_ITEM_SET(state, data) {
  //   state.currentItem = data;
  // },
  // RESET(state) {
  //   Object.assign(state, initialState());
  // },
  SET_FIELDS(state, data) {
    state.list.fields = data;
  },
  SET_FILTERS(state, data) {
    state.filters = data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
