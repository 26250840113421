/<template>
  <div>
    <b-table :striped="striped" hover :items="items" :fields="fields" small>
      <!-- Footer -->
      <!-- could also be a footer field slot instead -->
      <template slot="bottom-row">
        <td class="footer-row"><strong>Total</strong></td>
        <!-- this is a computed prop that adds up all the expenses in the visible rows -->
        <td class="footer-row">
          <span
            ><strong>{{ totalValue }}</strong></span
          >
        </td>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  props: {
    items: { type: Array, default: null },
    title: { type: String, default: "Description" },
    striped: { type: Boolean, default: false },
    total: { type: String, default: null }
  },
  data() {
    return {
      fields: [
        {
          key: "field",
          sortable: false,
          label: this.title,
          class: "col-350"
        },
        {
          key: "value",
          sortable: false,
          label: "",
          class: "col-right"
        }
      ]
    };
  },
  created() {},
  computed: {
    totalValue() {
      if (this.total) return this.total;
      return this.items.reduce((accum, item) => {
        return accum + parseFloat(item.value);
      }, 0.0);
    }
  }
};
</script>

<style>
.footer-row {
  border-top: 1px solid #bbb !important;
}
</style>