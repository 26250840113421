<template>
	<b-container fluid class="item-wrapper">
        <div>
            <b-overlay
				variant="white"
				:show="isLoading && showLoading"
				:opacity="0.0"
				spinner-variant="info"
			>
				<BaseAlert :mode="mode" width="35em" :timeout="7" />
				<b-form
					@submit.prevent="formSave"
					@keydown.enter.exact="formSave"
					@keydown.esc.exact="formCancel"
					enctype="multipart/form-data"
				>
					<!-- Main form card -->
					<b-card
						no-body
						header-tag="header"
						footer-tag="footer"
						class="no-border"
					>
						<!-- Header -->
						<template #header>
							<div class="item-modal-header-content">
								<!-- Record id -->
								<div>
									<span class="mr-1 text-muted small">
										{{ mode === "new" ? "New Item" : `Item id:` }}</span
									>
									<span class="small">{{ form.id }}</span>
								</div>
								<!-- Description  -->
								<h5 class="item-header-description">
									{{ headerDescription() }}
								</h5>
								<button
									@click="formCancel"
									class="item-close-btn"
									aria-label="Close"
								>
									&times;
								</button>
							</div>
						</template>
						<!-- Card body slot -->
						<b-card no-body class="bg-light">
							<b-tabs
								card
								nav-class="text-capitalize"
								small
								active-nav-item-class="font-weight-bold text-uppercase text-info"
								content-class="mt-0 p-2"
							>
								<!-- MAIN TAB -->
								<b-tab lazy title="Main" class="bg-light">
									<template>
										<b-form-row>
											<b-col cols="7" class="mr-2 pr-3">
												<!-- productId (asin) -->
												<BaseFormInput
													id="productId"
													label="Asin:"
													:labelCols=3
													autofocus
													v-model="form.productId"
													:state="getFieldState('productId')"
													:readonly="!isAuthorized('PurchasedItem', 'update')"
													@input="$v.form.productId.$touch()"
													:error="$v.form.productId.$error"
													:errorMsg="`Error in product id (Asin)`"
													compact
													size="sm"
												/>
												<BaseFormInput
													id="sku"
													label="SKU:"
													:labelCols=3
													v-model="form.sku"
													:state="getFieldState('sku')"
													compact
													size="sm"
												/>
												<!-- productDescription -->
												<BaseFormTextarea
													id="productDescription"
													:label-cols=3
													placeholder="Product description"
													autocomplete="off"
													label="Description"
													v-model="form.productDescription"
													:state="getFieldState('productDescription')"
													@input="$v.form.productDescription.$touch()"
													@keydown.enter.stop
													:readonly="!isAuthorized('PurchasedItem', 'update')"
													:error="$v.form.productDescription.$error"
													errorMsg="Product description is required"
													size="sm"
													:rows="3"
													compact
												/>
												<BaseFormDate
                          id="purchasedOn"
													:inline="true"
													:labelCols=3
													label="Purchased on:"
													v-model="form.purchasedOn"
                          :state="getFieldState(form.purchasedOn)"
													@input="$v.form.purchasedOn.$touch()"
													size="sm"
													compact
												/>
												<!-- Purchased qty -->
												<BaseFormSpinButton
													id="purchasedQty"
													label="Purchased qty: "
													ref="purchasedQty"
													:labelCols="mode === 'new' ? 3 : 3"
													:inline="true"
													:autofocus="true"
													:min="0"
													:max="999"
													:readonly="!isAuthorized('PurchasedItem', 'update')"
													v-model="form.purchasedQty"
                          :state="getFieldState('purchasedQty')"
													@input="$v.form.purchasedQty.$touch()"
													:error="$v.form.purchasedQty.$error"
													:errorMsg="`Error in purchasedQty`"
													compact
													size="sm"
												/>
												<!-- Receive progress -->
												<b-progress
													class="mt-2 mb-2"
													:style="{ marginLeft: '111px' }"
												>
													<b-progress-bar
														label="Receive progress"
														:value="form.totalReceivedQty"
														:max="form.purchasedQty"
														variant="info"
														show-progress
													></b-progress-bar>
												</b-progress>
											</b-col>
											<b-col>
												<!-- Image -->
												<b-card no-body class="mb-1" v-if="itemImage()">
													<b-card-body>
														<b-container class="image-container">
															<b-img
																:src="itemImage()"
																fluid
																alt="Item image"
																class="mx-auto my-auto"
																height="100"
															></b-img>
														</b-container>
													</b-card-body>
												</b-card>
												<!-- Asin clickable link -->
												<div class="pb-3">
													<span class="text-muted small" v-if="form.productId">
														<b-link
															id="amazon-link"
															target="_blank"
															:href="`https://www.amazon.com/dp/${form.productId}/`"
															rel="noopener noreferrer"
														>
															{{ `${form.productId} ` }}
														</b-link>
														<b-tooltip target="amazon-link"
															>Click to see Amazon listing</b-tooltip
														>
													</span>
												</div>
												<!-- Qty fields -->
												<BaseFormSpinButton
                          v-for="field in quantityFields"
													:key="field.id"
													:id="field.id"
													:label="field.label"
													:ref="field.id"
													:labelCols="mode === 'new' ? 4 : 4"
													:inline="true"
													:autofocus="true"
													:min="0"
													:max="999"
													size="sm"
													:readonly="!isAuthorized('PurchasedItem', 'update')"
													v-model="field.value"
                          :state="getFieldState(field.id)"
													@input="$v.form[field.id].$touch()"
													:error="$v.form[field.id].$error"
													:errorMsg="`Error in ${field.label}`"
													compact
												/>

											</b-col>
										</b-form-row>
									</template>
								</b-tab>
								<!-- PRODUCT INFO -->
								<b-tab lazy title="Product Info	" class="bg-light">
									<template>
										<b-form-row>
											<b-col cols="6" class="mr-2 pr-3">
												<!-- Source -->
												<BaseFormCombo
													id="source"
													label="Source: "
													:namespace="namespace"
													:labelCols=4
													v-model="form.source"
													:readonly="!isAuthorized('PurchasedItem', 'update')"
                          :state="getFieldState('source')"
													@input="$v.form.source.$touch()"
													:error="$v.form.source.$error"
													:errorMsg="`Error in source`"
													compact
													size="sm"
												/>
												<!-- Supplier -->
												<BaseFormCombo
													id="supplier"
													label="Supplier: "
													:namespace="namespace"
													:labelCols=4
													v-model="form.supplier"
													:readonly="!isAuthorized('PurchasedItem', 'update')"
                          :state="getFieldState('supplier')"
													@input="$v.form.supplier.$touch()"
													:error="$v.form.supplier.$error"
													:errorMsg="`Error in supplier`"
													compact
													size="sm"
												/>
												<BaseFormInput
													v-for="field in productDetailInputLeft"
													:key="field.id"
													:id="field.id"
													:label="field.label"
													:labelCols=4
													v-model="field.value"
													:readonly="!isAuthorized('PurchasedItem', 'update')"
                          :state="getFieldState(field.id)"
													@input="$v.form[field.id].$touch()"
													:error="$v.form[field.id].$error"
													:errorMsg="`Error in ${field.label}`"
													compact
													size="sm"
												/>
											</b-col>
											<b-col class="mr-2 pr-3">
												<!-- cost -->
												<BaseCurrencyInput
													id="cost"
													label="Cost:"
													labelAlign="right"
													:labelCols=3
													v-model="form.cost"
													:state="getFieldState('cost')"
													placeholder="Item cost"
													@input="$v.form.cost.$touch()"
													:error="$v.form.cost.$error"
													:errorMsg="`Error in cost`"
													compact
													size="sm"
												/>
												<!-- msrp -->
												<BaseCurrencyInput
													id="msrp"
													label="MSRP:"
													labelAlign="right"
													:labelCols=3
													v-model="form.msrp"
													:state="getFieldState('msrp')"
													@input="$v.form.msrp.$touch()"
													:error="$v.form.msrp.$error"
													:errorMsg="`Error in MSRP`"
													placeholder="Item MSRP"
													compact
													size="sm"
												/>
												<!-- Expiry -->
												<BaseFormDate
                          id="expiry"
													:inline="true"
													labelAlign="right"
													:labelCols=3
													label="Expiry:"
													v-model="form.expiry"
                          :state="getFieldState(form.expiry)"
													@input="$v.form.expiry.$touch()"
													size="sm"
													compact
												/>
												<BaseFormInput
													id="upc"
													label="UPC: "
													:labelCols=3														labelAlign="right"
													v-model="form.upc"
													:readonly="!isAuthorized('PurchasedItem', 'update')"
                          :state="getFieldState(form.upc)"
													@input="$v.form.upc.$touch()"
													:error="$v.form.upc.$error"
													:errorMsg="`Error in UPC`"
													compact
													size="sm"
												/>
											</b-col>
										</b-form-row>
										<b-form-row>
											<b-col>
											<hr />
											<BaseFormInput
												id="orderNumber"
												label="Order number: "
												:labelCols=2
												:marginLeftValue=-4
												:textFieldWidth=641
												v-model="form.orderNumber"
												:readonly="!isAuthorized('PurchasedItem', 'update')"
												:state="getFieldState(form.orderNumber)"
												@input="$v.form.orderNumber.$touch()"
												:error="$v.form.orderNumber.$error"
												:errorMsg="`Error in Order number`"
												compact
												size="sm"
											/>
											<BaseFormInput
												id="trackingNumber"
												label="Tracking number: "
												:labelCols=2
												:marginLeftValue=-4
												:textFieldWidth=641
												v-model="form.trackingNumber"
												:readonly="!isAuthorized('PurchasedItem', 'update')"
												:state="getFieldState(form.trackingNumber)"
												@input="$v.form.trackingNumber.$touch()"
												:error="$v.form.trackingNumber.$error"
												:errorMsg="`Error in Tracking number`"
												compact
												size="sm"
											/>
											</b-col>
										</b-form-row>
									</template>
								</b-tab>
								<!-- OTHER NOTES -->
								<b-tab lazy title="Other Notes" class="bg-light">
									<template>
										<b-form-row>
											<b-col cols="6" class="mr-2 pr-3">
												<!-- Processor facility - Select -->
												<BaseFormSelect
													id="processorFacilityid"
													name="processorFacilityId"
													label="Processor facility:"
													:labelCols="mode === 'new' ? 4 : 4"
													:namespace="namespace"
													fieldName="processorFacilityName"
													optionsField="itemOptions"
													text-field="processorFacilityName"
													value-field="processorFacilityId"
													v-model="form.processorFacilityId"
													:state="getFieldState('processorFacilityId')"
													@input="$v.form.processorFacilityId.$touch()"
													@g3Change="optionFieldChange"
													:disabled="!isAuthorized('PurchasedItem', 'update')"
													:error="$v.form.processorFacilityId.$error"
													errorMsg="Processor facility is required"
													@blur="$v.form.processorFacilityId.$touch()"
													compact
													size="sm"
												/>
												<!-- Processor line of business - Select -->
												<BaseFormSelect
													id="processorLobId"
													name="processorLobId"
													label="Processor line:"
													:labelCols="mode === 'new' ? 4 : 4"
													:namespace="namespace"
													fieldName="processorLobName"
													optionsField="itemOptions"
													text-field="processorLobName"
													value-field="processorLobId"
													v-model="form.processorLobId"
													:state="getFieldState('processorLobId')"
													@input="$v.form.processorLobId.$touch()"
													@g3Change="optionFieldChange"
													:disabled="!isAuthorized('PurchasedItem', 'update')"
													:error="$v.form.processorLobId.$error"
													errorMsg="Processor line of business is required"
													@blur="$v.form.processorLobId.$touch()"
													compact
													size="sm"
												/>
											</b-col>
											<b-col class="mr-2 pr-3">
												<!-- Client facility - Select -->
												<BaseFormSelect
													id="clientFacilityId"
													name="clientFacilityId"
													label="Client facility:"
													labelAlign="right"
													:labelCols="mode === 'new' ? 4 : 4"
													:namespace="namespace"
													fieldName="clientFacilityName"
													optionsField="itemOptions"
													text-field="clientFacilityName"
													value-field="clientFacilityId"
													v-model="form.clientFacilityId"
													:state="getFieldState('clientFacilityId')"
													@input="$v.form.clientFacilityId.$touch()"
													@g3Change="optionFieldChange"
													:disabled="!isAuthorized('PurchasedItem', 'update')"
													:error="$v.form.clientFacilityId.$error"
													errorMsg="Client facility is required"
													@blur="$v.form.clientFacilityId.$touch()"
													compact
													size="sm"
												/>
												<!-- Client line of business - Select -->
												<BaseFormSelect
													id="clientLobId"
													name="clientLobId"
													label="Client line:"
													labelAlign="right"
													:labelCols="mode === 'new' ? 4 : 4"
													:namespace="namespace"
													fieldName="clientLobName"
													optionsField="itemOptions"
													text-field="clientLobName"
													value-field="clientLobId"
													v-model="form.clientLobId"
													:state="getFieldState('clientLobId')"
													@input="$v.form.clientLobId.$touch()"
													@g3Change="optionFieldChange"
													:disabled="!isAuthorized('PurchasedItem', 'update')"
													:error="$v.form.clientLobId.$error"
													errorMsg="Client line of business is required"
													@blur="$v.form.clientLobId.$touch()"
													compact
													size="sm"
												/>
											</b-col>
										</b-form-row>
										<hr />
										<b-form-row>
											<b-col cols="12">
												<!-- itemNotes -->
												<BaseFormTextarea
													id="itemNotes"
													:label-cols=2
													autofocus
													autocomplete="off"
													label="Item notes"
													v-model="form.itemNotes"
													:state="getFieldState('itemNotes')"
													@input="$v.form.itemNotes.$touch()"
													@keydown.enter.stop
													:readonly="!isAuthorized('PurchasedItem', 'update')"
													:error="$v.form.itemNotes.$error"
													size="sm"
													:rows="3"
												/>
												<!-- clientNotes -->
												<BaseFormTextarea
													id="clientNotes"
													:label-cols=2
													autocomplete="off"
													label="Client notes"
													v-model="form.clientNotes"
													:state="getFieldState('clientNotes')"
													@input="$v.form.clientNotes.$touch()"
													@keydown.enter.stop
													:readonly="!isAuthorized('PurchasedItem', 'update')"
													:error="$v.form.clientNotes.$error"
													size="sm"
												/>
												<!-- processorNotes -->
												<BaseFormTextarea
													id="processorNotes"
													:label-cols=2
													autocomplete="off"
													label="Processor notes"
													v-model="form.processorNotes"
													:state="getFieldState('processorNotes')"
													@input="$v.form.processorNotes.$touch()"
													@keydown.enter.stop
													:readonly="!isAuthorized('PurchasedItem', 'update')"
													:error="$v.form.processorNotes.$error"
													size="sm"
												/>
											</b-col>
										</b-form-row>
									</template>
								</b-tab>
								<!-- DETAIL/HISTORY -->
								<b-tab lazy title="Detail/History	" class="bg-light">
									<template>
										<div class="m-3">
											<b-form-row>
												<h4 class="pb-9 pl-1 history-heading">Record Detail</h4>
											</b-form-row>
											<b-form-row>
												<b-col cols="7">
													<div>
														<!-- Top-left controls -->
														<BaseFormInput
															v-for="item in topLeft"
															:key="item.id"
															:id="item.id"
															:label="item.label"
															:labelCols="4"
															plaintext
															labelClass="text-muted"
															size="sm"
															readonly
															compact
															:value="item.value"
														/>
													</div>
												</b-col>
												<b-col>
													<div>
														<!-- Top-right controls -->
														<BaseFormInput
															v-for="item in topRight"
															:key="item.id"
															:id="item.id"
															:label="item.label"
															:labelCols="5"
															labelAlign="right"
															plaintext
															labelClass="text-muted"
															size="sm"
															readonly
															compact
															:value="item.value"
														/>
													</div>
												</b-col>
											</b-form-row>
											<hr />
											<!-- History -->
											<b-form-row>
												<h4 class="pb-0 pl-1 history-heading">Record History</h4>
											</b-form-row>
											<div v-if="noHistory" class="no-history">No history to display</div>
											<div v-if="!noHistory">
												<div class="history-scrollable-list">
													<b-list-group
														v-for="(hx, index) in currentItem.logs"
														:key="index"
														class="p-0 history-item"
													>
														<b-list-group-item class="px-3 py-1">
															<BaseLogItem :item="hx" />
														</b-list-group-item>
													</b-list-group>
												</div>
											</div>
										</div>
									</template>
								</b-tab>
							</b-tabs>
						</b-card>
						<!-- Card footer slot -->
						<template #footer>
							<div class="d-flex justify-content-end">
								<div class="d-flex">
									<b-button
										class="mr-3"
										variant="secondary"
										@click="formCancel"
										>{{ isFormDirty ? "Cancel" : "Close" }}</b-button
									>
									<b-button
										type="submit"
										variant="primary"
										:disabled="
											!isFormDirty && mode !== 'new' 
										"
										v-if="isAuthorized('PurchasedItem', 'update')"
										>Save</b-button
									>
								</div>
							</div>
						</template>
					</b-card>	
				</b-form>
			</b-overlay>
		</div>	
	</b-container>
</template>

<script>
import { mapGetters } from "vuex";
import { required, decimal } from "vuelidate/lib/validators";
import {
	handleBeforeDestroy,
	handleCreated,
	handleFormCancel,
	handleFormSave,
	handleItemImage,
	handleSyncOptionName,
	handleValidateState
} from "../../utils/component-utils";

export default {
	components: {},
	name: "PurchasedItem",
	props: {
		namespace: { type: String, default: "PurchasedItem" },
		itemId: { type: Number, default: -1 },
	},
	data() {
		return {
			mode: { type: String, default: "New" },
			isModalVisible: false,
			isDataLoaded: false,
			form: {
				id: this.$props.itemId,
				productDescription: "",
				productId: "",
				purchasedQty: 0,
				purchasedOn: "",
				extraQty: 0,
				canceledQty: 0,
				damagedQty: 0,
				totalReceivedQty: 0,
				pctComplete: 0,
				expiry: "",
				cost: 0,
				msrp: 0,
				orderNumber: "",
				sku: "",
				fnsku: "",
				upc: "",
				source: "",
				supplier: "",
				trackingNumber: "",
				processorFacilityId: 0,
				processorFacilityName: "",
				processorName: "",
				clientFacilityId: 0,
				clientFacilityName: "",
				clientName: "",
				tenantId: "",
				clientId: "",
				createdOn: "",
				ts: "",
				createdBy: "",
				lastUpdatedBy: "",
				processorLobId: 0,
				processorLobName: "",
				clientLobId: 0,
				clientLobName: "",
				processorNotes: "",
				clientNotes: "",
				itemNotes: "",
			},
			confirmingChanges: false,
			childDirty: false,
			sourcPathName: ""
		}
	},
	validations: {
		form: {
			productDescription: { required },
			productId: { required },
			purchasedQty: { required },
			purchasedOn: { required },
			extraQty: { required },
			canceledQty: { required },
			damagedQty: { required },
			expiry: {},
			cost: { decimal },
			msrp: { decimal },
			orderNumber: {},
			sku: {},
			fnsku: {},
			upc: {},
			source: {},
			supplier: {},
			trackingNumber: {},
			clientFacilityId: {},
			processorFacilityId: {},
			clientLobId: {},
			processorLobId: {},
			processorNotes: {},
			clientNotes: {},
			itemNotes: {}
		},
		focusedElement: "",
	},
	async created() {
		try {
			await handleCreated(this, document);
			// Reset form validation
			this.$nextTick(() => {
				this.$v.$reset();
			})
			this.isDataLoaded = true;
		} catch (error) {
			console.error("Error during component creation", error)
		}
	},
	mounted() {
	},
	computed: {
		...mapGetters("Session", [
			"isLoading",
			"showLoading",
			"isAuthorized",
			"user",
			"authToken",
		]),
		// Store to component data binding
		currentItem() {
			return this.$store.getters[`${this.namespace}/currentItem`];
		},
		isFormDirty() {
			return this.childDirty || this.$v.$anyDirty;
		},
		sourceOptions() {
			return ["one", "two", "three"]
		},
		//* **************************************
		//           Entity-specific Computed
		//* **************************************
		quantityFields() {
			return [
				{ id: 'extraQty', label: 'Extra qty:', value: this.form.extraQty },
				{ id: 'canceledQty', label: 'Canceled qty:', value: this.form.canceledQty },
				{ id: 'damagedQty', label: 'Damaged qty:', value: this.form.damagedQty }
			]
		},
		productDetailInputLeft() {
			return [
				{ id: 'sku', label: 'SKU:', value: this.form.sku },
				{ id: 'fnsku', label: 'FnSku:', value: this.form.fnsku },
			]
		},
		// Detail/history
		topLeft() {
			return [
				{ id: 'id', label: 'Record id:', value: this.form.id },
				{ id: 'processorName', label: 'Processor name:', value: this.form.processorName },
				{ id: 'clientName', label: 'Client name:', value: this.form.clientName },
				{ id: 'tenantId', label: 'Tenant id:', value: this.form.tenantId },
				{ id: 'clientId', label: 'Client id:', value: this.form.clientId },

			]
		},
		// Detail/history
		topRight() {
			return [
				{ id: 'createdOn', label: 'Created on:', value: new Date(this.form.createdOn).toLocaleString() },
				{ id: 'createdBy', label: 'Created by:', value: this.form.createdBy },
				{ id: 'lastEditedOn', label: 'Last updated on:', value: new Date(this.form.ts).toLocaleString() },
				{ id: 'lastUpdatedBy', label: 'Last updated by:', value: this.form.lastUpdatedBy },
			]
		},
		noHistory() {
			if (!this.isDataLoaded || !this.currentItem) return true;
			if (this.currentItem && this.currentItem.logs && this.currentItem.logs.length > 0) {
				return false;
			} else {
				return true;
			}
		}
	},
	methods: {
		// *********************************************
		//          Entity-specific Methods
		// *********************************************
		formatCurrency(field) {
			const numericValue = parseFloat(this.form[field].replace(/[^0-9.-]+/g, ""));
			if (!isNaN(numericValue)) {
				this.form[field] = numericValue.toFixed(2);
			} else {
				this.form[field] = "";
			}
		},
		headerDescription() {
			return `Purchased Item: ${this.form.productId}`
			// const desc = this.form.productDescription?.length > 65
			// 	? this.form.productDescription?.substring(0, 65) + "..."
			// 	: this.form.productDescription
			// return desc?.replace(".", "").replace(",", "").replace("!", "").replace(":", "")
		},
		optionFieldChange({ field, value }) {
			// Option dropdowns: Sync id and name
			if (field === 'processorFacility_Id') {
				this.optionFieldSync(value, field, 'processorFacilityName');
			} else if (field === "clientFacilityId") {
				this.optionFieldSync(value, field, 'clientFacilityName');
			} else if (field === "tenantLobId") {
				this.optionFieldSync(value, field, 'processorLobName');
			} else if (field === "clientLobId") {
				this.optionFieldSync(value, field, 'clientLobName');
			}
		},
		optionFieldSync(value, valueField, textField) {
			handleSyncOptionName({ thisObj: this, value, valueField, textField, optionsField: "itemOptions" })
		},
		// *********************************************
		//          Standard Item Methods
		// *********************************************
		beforeDestroy() {
			handleBeforeDestroy(this, document)
		},
		detailModalUpdate(val) {
			this.isModalVisible = val; // Sync parent visibility with child
		},
		detailModalShow() {
			this.isModalVisible = true;
		},
		focusChanged(event) {
			const el = event.target;
			this.focusedElement = el.id;
		},
		getFieldState(data) {
			return handleValidateState(this, data)
		},
		itemImage() {
			return handleItemImage(this)
		},
		onInput(fieldId) {
			this.$v.form[fieldId].$touch();
		},
		// *********************************************
		//          Item validate/close/cancel Methods
		// *********************************************
		async formCancel() {
			await handleFormCancel(this)
		},
		async formSave() {
			await handleFormSave(this);
		},
	},
}
</script>

<style scoped>
</style>
