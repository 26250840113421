import { httpClient } from "./http-client";
import httpUtils from "./http-utils";

export default class CommonApis {
  constructor(endPoint) {
    this.END_POINT = endPoint;
  }
  // CRUD: Read one record
  async crudGetOne(endpoint, recordId, params = "") {
    let url = `${endpoint}/${recordId}${params}`;
    return await httpClient.get(url);
  }

  // CRUD: Read all records
  async crudGetAll(props) {
    const { endpoint, selectedFields, filters } = props;
    const url = await httpUtils.formatUrl(props, endpoint);
    const filter = await httpUtils.formatFilter(filters);
    const options = await httpUtils.formatOptions(selectedFields);
    const params = {};
    if (filter) {
      params.filter = filter;
    }
    if (options) {
      params["selectedFields"] = options;
    }
    return await httpClient.get(url, { params });
  }

  // CRUD: Update one record
  async crudUpdateOne(props) {
    const { endpoint, record } = props;
    const url = `${endpoint}/${record.id}`;
    return await httpClient.put(url, record);
  }

  async getAll(props) {
    const { selectedFields, filters, drilldownId, parentId } = props;
    let useEndPoint = this.END_POINT;
    if (drilldownId > -1) {
      // Handle drilldown
      useEndPoint = `${useEndPoint}${drilldownId}`;
    } else if (parentId) {
      // Handle get item detail records based on parent id
      useEndPoint = `${useEndPoint}/${parentId}/items`;
    }
    const url = httpUtils.formatUrl(props, useEndPoint);
    const filter = httpUtils.formatFilter(filters);
    const options = httpUtils.formatOptions(selectedFields);
    const params = {};
    if (filter) {
      params.filter = filter;
    }
    if (options) {
      params["selectedFields"] = options;
    }
    return await httpClient.get(url, { params });
  }

  // async getCalculatedFields(props) {
  //   const { filters, searchText } = props;
  //   const url = `${this.END_POINT}${
  //     props.drilldownId > -1 ? props.drilldownId : ""
  //   }/calculated-fields`;
  //   // Add search text?
  //   const searchString = searchText ? `?searchText=${searchText}` : "";
  //   const useUrl = `${url}${searchString}`;
  //   // Add filter?
  //   const filter = httpUtils.formatFilter(filters);
  //   if (filter) {
  //     return await httpClient.get(useUrl, {
  //       params: { filter: filter },
  //     });
  //   }
  //   return await httpClient.get(useUrl);
  // }

  async getCustom(url) {
    return await httpClient.get(url);
  }

  // you can pass arguments to use as request parameters/data
  async getOne(recordId, detail = false) {
    let url = `${this.END_POINT}/${recordId}`;
    if (detail) {
      url = `${url}`;
    }
    return await httpClient.get(url);
  }

  // you can pass arguments to use as request parameters/data
  async getLogsForOne(recordId) {
    const url = `${this.END_POINT}/logs/${recordId}`;
    return await httpClient.get(url);
  }

  // POST custom url
  async postCustom(url, body) {
    return await httpClient.post(url, body);
  }

  // maybe more than one..
  async createOne(record) {
    return await httpClient.post(this.END_POINT, record);
  }

  // Update a record
  async updateOne(record) {
    const url = `${this.END_POINT}/${record.id}`;
    return await httpClient.put(url, record);
  }

  // Delete a record
  async deleteOne(record) {
    const url = `${this.END_POINT}/${record.id}`;
    return await httpClient.delete(url);
  }

  async deleteCustom(url) {
    return await httpClient.delete(url);
  }

  // Delete multiple records based on id
  async deleteManyById(recordIds) {
    const url = `${this.END_POINT}/many`;
    return await httpClient.post(url, recordIds);
  }
}
