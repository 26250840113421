<template>
  <div id="pond">
    <file-pond
      name="issue-attachments"
      ref="pond"
      label-idle="Drop files (or paste [ctl-v]) here..."
      :allow-multiple="true"
      accepted-file-types="image/*, application/pdf"
      :files="attachments"
      @init="handleInit"
    />
  </div>
  <!-- @processfile="handleProcess" -->
</template>


<script>
// Import Vue FilePond
import vueFilePond, { setOptions } from "vue-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
// Import FilePond plugins
// Please note that you need to install these plugins separately

// Import image preview plugin styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import "filepond-plugin-image-overlay/dist/filepond-plugin-image-overlay.min.css";

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageOverlay from "filepond-plugin-image-overlay";
import { getToken } from "../api/http-client";

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageOverlay
);

export default {
  components: {
    FilePond
  },
  props: {
    namespace: { type: String, default: "" },
    recId: {
      type: [String, Number],
      default: -1
    }
  },
  data() {
    return {
      myFiles: []
    };
  },
  computed: {
    currentItem() {
      return this.$store.getters[`${this.namespace}/currentItem`];
    },
    attachments() {
      return this.currentItem.attachments;
    }
  },
  methods: {
    async handleInit() {
      const headers = {
        Authorization: `Bearer ${await getToken()}`,
        IssueId: this.recId
      };
      // example of instance method call on pond reference
      //      this.$refs.pond.getFiles();
      console.log("handleFilePondInit");
      await setOptions({
        server: {
          url: `${process.env.VUE_APP_BASE_URL}/issue/`,
          process: {
            url: "process",
            headers
            // onload: response => {this.$refs.pond.addFile(this.handleProcess(response)}
          },
          revert: {
            url: "revert",
            headers
            // onload: response => this.handleRemove(response)
          },
          restore: {
            url: "restore",
            headers
          },
          load: {
            url: "load",
            headers
          },
          fectch: {
            url: "fetch",
            headers
          }
        },
        onprocessfile: (error, file) => {
          if (!file?.file) return;
          this.$store.commit(`${this.namespace}/ATTACHMENT_ADD`, file);
          //this.myFiles.push(file);
          console.log("attachments", this.attachments);
        },
        onremovefile: (error, file) => {
          if (!file?.file) return;
          this.$store.commit(`${this.namespace}/ATTACHMENT_REMOVE`, file.id);
          // const index = this.myFiles.findIndex(file => file.id === file.id);
          // if (index > -1) {
          //   this.myFiles.splice(index, 1);
          // }
          console.log("attachments", this.attachments);
        }
      });
      this.$refs.pond.getFiles();
    },

    async handleProcess(file) {
      console.log("handleProcess.file:", file);
      //this.$refs.pond.addFile(fileId);

      //this.$store.commit(`${this.namespace}/ATTACHMENT_ADD`, fileId);
    },
    // async handleProcess(
    //   fieldName,
    //   file,
    //   metadata,
    //   load,
    //   error,
    //   progress,
    //   abort
    // ) {
    //   //console.log("handleProcess", error, file.filename);
    //   //this.myFiles.push(file.filename);
    //   console.log(
    //     "handlProcess 2: fieldName, file, metadata, load, error, progress, abort: ",
    //     fieldName,
    //     file,
    //     metadata,
    //     load,
    //     error,
    //     progress,
    //     abort
    //   );
    // },
    // async handleRevert(error, file) {
    //   console.log("handleRevert", { error, file });
    //   //this.myFiles.push(file.file);
    //   const index = this.myFiles.indexOf(file.id);
    //   console.log("handleRevert.file.file.id:", file.id);
    //   console.log("handleRevert.index:", index);
    //   if (index > -1) {
    //     this.myFiles.splice(index, 1);
    //   }
    // },
    async handleRemove(error, file) {
      console.log("handleRemove", { error, file });
      this.$store.commit(`${this.namespace}/ATTACHMENT_REMOVE`, file.serverId);
      // //   console.log("handleRemove .file.id:", file.id);
      // const index = this.myFiles.findIndex(file => file.id === file.id);
      // //const index = this.myFiles.indexOf(file.id);
      // //   console.log("handleRemove .index:", index);
      // if (index > -1) {
      //   this.myFiles.splice(index, 1);
      // }
    }
    // async handleAddFile(error, file) {
    //   console.log("handleAddFile", { error, file });
    // }
  }
};
</script>

<style lang="css" scoped>
</style>