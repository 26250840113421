import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import { BootstrapVue, BootstrapVueIcons, BVToastPlugin } from "bootstrap-vue";
//import Vuelidate from "vuelidate"
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(BVToastPlugin);
//Vue.use(Vuelidate)
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

// **************************************************************
//  g3tools-lap-ui UI Application
//  09/05/2024 - Version 2
// **************************************************************

// Auto-register base components
const requireComponent = require.context(
  "./components",
  false,
  /Base[A-Z]\w+\.(vue|js)$/
);
requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);
  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, "$1"))
  );
  Vue.component(componentName, componentConfig.default || componentConfig);
});

Vue.config.productionTip = false;

// Custom directives
Vue.directive("uppercase", {
  update(el, binding) {
    const doUpperCase = binding.value;
    const sourceValue = el.value;
    const newValue = doUpperCase ? sourceValue.toUpperCase() : sourceValue;
    if (sourceValue !== newValue) {
      el.value = newValue;
      el.dispatchEvent(new Event("input", { bubbles: true }));
    }
  },
});

// Start vue
// new Vue({
//   el: "#app",
//   router,
//   store,
//   render: (h) => h(App),
// }).$mount("#app");

// Initialize stores before starting Vue app
//store.dispatch('initializeStore').then(() => {
  // Only start Vue after store is initialized
  new Vue({
    el: '#app',
    router,
    store, // Register the store with the Vue instance
    render: (h) => h(App),
  }).$mount('#app');
// }).catch(error => {
//   console.error('Failed to initialize store:', error);
// });