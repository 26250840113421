<template>
  <div>
    <div class="section">
      <form @submit.prevent="getFile" enctype="multipart/form-data">
        <div id="app">
          <input
            type="file"
            ref="file"
            @change="getFile"
            class=""
            accept=".xlsx,.csv,.txt"
          />
        </div>
      </form>
    </div>
    <div class="section">
      <div class="container">
        <form @submit.prevent="sendFile" enctype="multipart/form-data">
          <div
            v-if="message"
            :class="`message ${error ? 'is-danger' : 'is-success'}`"
          >
            <div class="message-body">{{ message }}</div>
          </div>
          <div class="field">
            <div class="file is-boxed is-primary">
              <label class="file-label">
                <input
                  type="file"
                  ref="file"
                  class="file-input"
                  @change="selectFile"
                />
                <span class="file-cta">
                  <span class="file-icon"> <i class="fas fa-upload"></i> </span>
                  <div class="span file-label">Choose a file...</div>
                </span>
                <span v-if="file" class="file-name">{{ file.name }}</span>
              </label>
            </div>
          </div>
          <div class="field">
            <button :disabled="error" class="button is-info">Send</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
//import axios from "axios";
import { httpClient } from "../api/http-client";
import config from "../../config";
import { readFile, utils } from "xlsx";

export default {
  components: {},
  props: {
    namespace: { type: String, default: "" },
    recId: {
      type: [String, Number],
      default: -1
    }
  },
  data() {
    return {
      file: "",
      fileData: "",
      message: "",
      error: false
    };
  },
  methods: {
    async getFile(e) {
      e.preventDefault();
      // Pre-validate files from file input dropzone
      const files = [this.$refs.file.files[0]];
      console.log("files:", files);
      // _.forEach(files, file => {
      //   const validateMessage = this.validate(file);
      //   if (validateMessage !== "") {
      //     // At least one file is in error: Stop
      //     this.message = validateMessage;
      //     this.error = true;
      //     return;
      //   }
      // });
      // if (this.message !== "") return;
      // await this.sendFile(this.$refs.file.files[0]);
    },

    onChange(e) {
      e.preventDefault();
      // Pre-validate files from file input dropzone
      this.message = "";
      this.error = false;
      this.info = false;
      const files = [this.$refs.file.files[0]];
      console.log("files:", files);
      this.file = event.target.files ? event.target.files[0] : null;
      console.log("onChange - this.$refs.file.files: ", this.$refs.file.files);
      console.log("onChange - event: ", event);
      console.log("onChange - this.file: ", this.file);
      if (this.file) {
        // Reading our test file
        const file = readFile(this.file.name);
        let data = [];
        const sheets = file.SheetNames;
        for (let i = 0; i < sheets.length; i++) {
          const temp = utils.sheet_to_json(file.Sheets[file.SheetNames[i]]);
          temp.forEach(res => {
            data.push(res);
          });
        }
        // Printing data
        console.log(data);

        // const reader = new FileReader();
        // reader.onload = e => {
        //   /* Parse data */
        //   const bstr = e.target.result;
        //   const wb = XLSX.read(bstr, { type: "binary" });
        //   /* Get first worksheet */
        //   const wsname = wb.SheetNames[0];
        //   const ws = wb.Sheets[wsname];
        //   /* Convert array of arrays */
        //   const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        //   console.log("file data:", data);
        // };
        // reader.readAsBinaryString(this.file);
      }
    },

    selectFile() {
      const file = this.$refs.file.files[0];
      const maxSize = config.fileUploads.MAX_SIZE;
      const allowedTypes = config.fileUploads.FILE_TYPES;
      const tooLarge = file.size > maxSize;
      if (allowedTypes.includes(file.type) && !tooLarge) {
        this.file = file;
        this.message = "";
        this.error = false;
      } else {
        this.error = true;
        this.message = tooLarge
          ? `File is too large. Max size is ${maxSize / 1000}KB`
          : "Only images allowed";
      }
    },
    async sendFile(e) {
      e.preventDefault();
      const formData = new FormData();
      formData.append("file", this.file);
      const url = `${process.env.VUE_APP_BASE_URL}/issue/upload`;
      try {
        await httpClient.post(url, formData, {
          headers: { issueId: this.recId }
        });
        this.message = "File has been uploaded";
        this.file = "";
        this.error = false;
      } catch (err) {
        this.message = err.data.error;
        this.error = true;
      }
    }
  }
};
</script>

<style lang="css" scoped src="../../node_modules/bulma/css/bulma.css">
.dropzone {
  height: 50px;
  background-color: #ccc;
}
</style>