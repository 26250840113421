const config = {
  app: {
    version: "2.0.2",
  },
  SCOPES: {
    TENANT: "tenant",
    CLIENT: "client",
  },
  maxTablePages: 7,
  autoListRefreshIntervalMin: 5,
  fetchDetailItemFromDb: true,
  mws: {
    throttleWaitTimeInSeconds: 120,
    batchPauseSeconds: 1.5,
  },
  testMode: false,
  fileUploads: {
    MAX_SIZE: 2000000,
    FILE_TYPES: ["image/jpeg", "image/png", "image/gif", "video/mp4"],
  },
  pendingDays: 21,
};

export default config;
