<template>
  <div>
    <b-dropdown
      class="kabab-button"
      v-if="
        isAuthorized(namespace, 'bulkUpdate') ||
        isAuthorized(namespace, 'delete')
      "
      right
      variant="link"
      toggle-class="text-decoration-none"
      no-caret
    >
      <template #button-content>
        <div class="kabab" />
        <!-- <span class="sr-only">Search</span> -->
      </template>
      <b-dd-item-btn
        :disabled="!selected"
        @click="actionClicked('change-selected')"
        v-if="
          isAuthorized(namespace, 'update') &&
          isAuthorized(namespace, 'bulkUpdate') &&
          recordFilters.length > 0
        "
      >
        <i class="fas fa-sync-alt" />
        Change Selected
      </b-dd-item-btn>
      <b-dd-divider
        v-if="
          isAuthorized(namespace, 'delete') &&
          recordFilters.length > 0 &&
          allowDelete
        "
      ></b-dd-divider>
      <b-dd-item-btn
        :disabled="!selected"
        @click="actionClicked('delete-selected')"
        v-if="isAuthorized(namespace, 'delete') && allowDelete"
      >
        <i class="fas fa-trash" />
        Delete Selected
      </b-dd-item-btn>
    </b-dropdown>
    <span>&nbsp;&nbsp;</span>
    <!-- ****************************************************** -->
    <!-- Change selected detail screen: Gather parameters (field and value) -->
    <!-- Based on record's filter defined in store -->
    <b-modal
      id="change-selected-modal"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      :cancel-title="$v.form.$anyDirty ? 'Cancel' : 'Close'"
      :cancel-disabled="isLoading"
      :ok-disabled="!$v.form.$anyDirty || isLoading"
      ok-title="Change Records"
      @cancel="handleCancel"
      @ok="handleOk"
    >
      <b-overlay
        variant="white"
        :show="isLoading"
        :opacity="0.0"
        spinner-variant="info"
        class="mx-auto"
      >
        <template #modal-title>
          {{ `Change Selected Records` }}
        </template>
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <!-- Field to change -->
          <b-form-group label-for="label" label="Field:" label-cols="4">
            <b-form-select
              id="label"
              ref="label"
              name="label"
              v-model="form.label"
              :state="validateState('label')"
              required
              :error="$v.form.label.$error"
              :options="fieldOptions"
              :aria-describedby="`field-live-feedback`"
              @input="$v.form.label.$touch()"
              @change="getNewValueOptions"
            >
            </b-form-select>
            <b-form-invalid-feedback :id="`field-live-feedback`">{{
              "Field is required"
            }}</b-form-invalid-feedback>
          </b-form-group>
          <!-- Value to change to -->
          <b-form-group label-for="newValue" label="New value:" label-cols="4">
            <b-form-select
              id="newValue"
              ref="newValue"
              name="newValue"
              v-model="form.newValue"
              :state="validateState('newValue')"
              required
              :error="$v.form.newValue.$error"
              :options="newValueOptions"
              :aria-describedby="`newValue-live-feedback`"
              @input="$v.form.newValue.$touch()"
            >
            </b-form-select>
            <b-form-invalid-feedback :id="`newValue-live-feedback`">{{
              "Value is required"
            }}</b-form-invalid-feedback>
          </b-form-group>
        </form>
        <!-- Progress -->
        <div v-if="selected">
          <span>Progress: </span>
          <span v-if="doneRecords > 0">{{ progressMsg }} </span>
          <b-progress
            :value="doneRecords"
            :max="selectedItems.length"
            show-progress
          ></b-progress>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
// import '../app.css'
import _ from "lodash";
import Vue from "vue";
import VueToast from "vue-toast-notification";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
// Import one of the available themes
// import 'vue-toast-notification/dist/theme-default.css'
// import 'vue-toast-notification/dist/theme-sugar.css'

Vue.use(VueToast);

export default {
  props: {
    namespace: { type: String, default: "" },
    allowDelete: { type: Boolean, default: true }
  },
  data() {
    return {
      //: this.selected,
      form: {
        label: "",
        newValue: ""
      },
      fieldOptions: [],
      newValueOptions: [],
      doneRecords: 0
    };
  },
  // Include any fields in validations that need to be marked dirty if touched
  validations: {
    form: {
      label: { required },
      newValue: { required }
    }
  },
  computed: {
    ...mapGetters("Session", ["isLoading", "isAuthorized"]),
    state() {
      return this.$store.state[this.namespace];
    },
    selected() {
      return this.state.list.selectedItems.length > 0;
    },
    selectedItems() {
      return this.$store.getters[`${this.namespace}/list`].selectedItems;
    },
    recordFilters() {
      return this.state.filters;
    },
    routeName() {
      return this.$route.name;
    },
    progressMsg() {
      return ` ${this.doneRecords} of ${this.selectedItems.length}`;
    }
  },
  methods: {
    async actionClicked(action) {
      //let i = 0;
      const confirmOptions = {
        title: "Please Confirm",
        okTitle: "Yes",
        okVariant: "danger",
        cancelTitle: "No",
        cancelVariant: "secondary",
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true
      };
      switch (action) {
        case "delete-selected":
          try {
            // Confirm delete
            this.$bvModal
              .msgBoxConfirm(
                `Are you sure you want to delete ${
                  this.selectedItems.length > 1 ? "these" : "this"
                } ${this.selectedItems.length} selected ${
                  this.selectedItems.length > 1 ? "items" : "item"
                }?`,
                confirmOptions
              )
              .then(async confirm => {
                if (confirm) {
                  await this.$store.dispatch(
                    `${this.namespace}/deleteSelectedItems`
                  );
                }
              });
          } catch (err) {
            const msg = `Error deleting record: ${err}`;
            this.$store.dispatch("Notification/toastErrorAdd", msg, {
              root: true
            });
          }
          break;
        // Bulk change selected records
        case "change-selected":
          this.doneRecords = 0;
          this.getParameters();
          break;
        default:
          break;
      }
    },
    async getParameters() {
      this.getFieldOptions();
      this.$bvModal.show("change-selected-modal");
    },
    async getFieldOptions() {
      const options = [];
      for (const filter of this.recordFilters) {
        if (!filter.allowBulkChange) continue;
        options.push(filter.label);
      }
      this.fieldOptions = options.sort();
    },
    async getNewValueOptions() {
      const label = this.form.label;
      const options = [];
      for (const filter of this.recordFilters) {
        if (!filter.allowBulkChange || filter.label !== label) continue;
        for (const option of filter.options) {
          if (filter.type === "action" && option.value === null) continue;
          options.push(option.text);
        }
        break;
      }
      this.newValueOptions = options;
    },
    validateState(name) {
      if (!this.$v.form[name]) return;
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async handleCancel() {},
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    async handleSubmit() {
      this.$v.form.$touch();
      // Exit when the form isn't valid
      if (this.$v.form.$anyError) {
        return;
      }
      // Save records to db
      await this.saveChanges();
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("change-selected-modal");
      });
    },
    async saveChanges() {
      try {
        // Get field name from filter label
        const foundFilter = this.recordFilters.find(
          x => x.label === this.form.label
        );
        if (!foundFilter) {
          throw `Error in BaseHeadingOtherAction.saveChanges: Filter label ${this.form.label} expected but not found in store ${this.$store}`;
        }
        const field = foundFilter.field;
        let i = 0;
        this.doneRecords = 0;
        // Change records based on filter
        for (const item of this.selectedItems) {
          // Change item field to selected value
          const foundItem = this.state.items.find(x => x.id === item.id);
          let clonedItem = _.cloneDeep(foundItem);
          clonedItem[field] = this.checkNewValue(field, this.form.newValue);
          // Save to list and database
          await this.$store.dispatch(`${this.namespace}/itemSave`, {
            item: clonedItem,
            postToast: false
          });
          i++;
          this.doneRecords++;
        }
        // Success: Tell user
        const msg = `Successfully changed ${
          i >= 200 ? "maximum of 200" : i
        } records`;
        this.$store.dispatch("Notification/toastMsgAdd", msg, {
          root: true
        });
        // Refresh list
        await this.$store.dispatch(
          `${this.namespace}/fetchAll`,
          this.routeName
        );
        this.$v.form.$reset();
        this.doneRecords = 0;
        this.$v.form.$touch();
      } catch (err) {
        const msg = `Error making bulk changes: ${err}`;
        this.$store.dispatch("Notification/toastErrorAdd", msg, {
          root: true
        });
      }
    },
    checkNewValue(field, newValue) {
      // If field is action bit field, convert from displayed text to value ( yes = 1, no = 0)
      // Is field an action field?
      const foundFilter = this.recordFilters.find(x => x.field === field);
      if (!foundFilter) return newValue;
      // Found filter
      if (foundFilter.type !== "action") return newValue;
      // Action filter
      const foundOption = foundFilter.options.find(x => x.text === newValue);
      if (!foundOption) return newValue;
      // Found action option
      if (foundOption.value === "0") return 0;
      if (foundOption.value === "1") return 1;
      return foundOption.value;
    }
  }
};
</script>

<style lang="css" scoped>
.kabab {
  border: none;
  width: 35px;
  height: 45px;
  margin-top: -1px;
  margin-left: -14px;
  padding-top: 4px;
  padding-left: 5px;
  padding-right: -5px;
  color: #333;
  transition: 0.2s;
}
.kabab:after {
  content: "\2807";
  font-size: 20px;
}
.kabab:hover {
  transform: scale(1.2, 1.2);
}
.kabab-button {
  border: none;
  /* margin-right: -5px; */
  height: 45px;
  width: 35px;
  padding: 0px;
  margin-top: 0px;
  padding-right: 5px;

}
</style>
