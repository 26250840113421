<template>
  <div>
    <b-form-group
      :id="id"
      :label="label"
      :label-for="`${id}-input`"
      :description="error ? '' : description"
      :label-cols="labelCols"
      :label-class="labelClass"
      :label-align="labelAlign"
      :label-size="size"
      class="label"
      :class="{ 'form-group--error': error, 'form-group--compact': compact }"
    >
      <b-form-textarea
        :id="`${id}-input`"
        :name="`${id}-input`"
        :value="value"
        @change="updateValue(id, $event)"
        @input="touchValue(id, $event)"
        v-bind="$attrs"
        :plaintext="plaintext"
        :placeholder="placeholder"
        :readonly="readonly"
        :style="`width: ${textFieldWidth}px; margin-left: ${marginLeftValue}px; background-color: ${backgroundColor}`"
        :rows="rows"
        :size="size"
        :max-rows="maxRows"
        v-on="$listeners"
        :no-resize="noResize"
        :state="state"
        :aria-describedby="`${id}-live-feedback`"
      ></b-form-textarea>
      <b-form-invalid-feedback :id="`${id}-live-feedback`">{{
        errorMsg
      }}</b-form-invalid-feedback>
    </b-form-group>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    compact: { type: Boolean, default: false },
    id: { type: String },
    description: { type: String },
    label: { type: String, default: "" },
    labelCols: { type: [Boolean, Number, String, null], default: null },
    labelClass: { type: String, default: "" },
    labelAlign: { type: String, default: "" },
    value: [String, Number],
    state: { type: [Boolean, null], default: null },
    rows: { type: [String, Number], default: 1 },
    size: { type: [String, Number], default: "" },
    maxRows: { type: [String, Number], default: 99 },
    error: { type: Boolean },
    errorMsg: { type: String },
    readonly: { type: Boolean, default: false },
    plaintext: { type: Boolean, default: false },
    noResize: { type: Boolean, default: false },
    placeholder: { type: String, default: "" },
    textFieldWidth: { type: String, default: "" },
    marginLeftValue: { type: Number, default: 0 },
    backgroundColor: {type: String, default: "white"}

  },
  methods: {
    updateValue(id, $event) {
      this.$emit("g3Input", { field: id, value: $event });
    },
    touchValue(id, $event) {
      this.$emit("g3Touch", { field: id, value: $event });
    }
  }
};
</script>

<style lang="css" scoped>
.form-group--compact {
  margin-bottom: .4rem;
}
</style>
