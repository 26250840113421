<template>
  <div>
    <b-form-group
      :id="id"
      :label="label"
      :label-for="`${id}-input`"
      :label-cols="labelCols"
      :description="error ? '' : description"
      :label-align="labelAlign"
      :label-size="size"
      :label-class="labelClass"
      class="label"
      :class="{
        'form-group--error': error,
        'form-group--compact': compact,
        'form-group--compact-readonly': compact && readonly,
        'text-bold': boldLabel,
      }"
    >
      <b-form-input
        :class="{
          'text-bold': boldValue,
          'right-align': rightAlignValue,
        }"
        :id="`${id}-input`"
        :name="`${id}-input`"
        :type="type"
        :value="value"
        @change="updateValue(id, $event)"
        @input="touchValue(id, $event)"
        v-bind="$attrs"
        v-uppercase="uppercase"
        :autocomplete="autocomplete"
        :plaintext="plaintext"
        :placeholder="placeholder"
        :readonly="readonly"
        :style="`width: ${textFieldWidth}px; margin-left: ${marginLeftValue}px`"
        :size="size"
        :state="state"
        :aria-describedby="`${id}-live-feedback`"
        v-on="$listeners"
        :tabindex="tabIndex"
      ></b-form-input>
      <b-form-invalid-feedback :id="`${id}-live-feedback`">
        {{ errorMsg }}
      </b-form-invalid-feedback>
      <!-- </b-input-group> -->
    </b-form-group>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    autocomplete: { type: String, default: "on" },
    compact: { type: Boolean, default: false },
    description: { type: String },
    error: { type: Boolean },
    errorMsg: { type: String },
    icon: { type: String, default: "" },
    id: { type: String },
    label: { type: String, default: "" },
    labelAlign: { type: String, default: "left" },
    labelClass: { type: String, default: "" },
    labelCols: { type: [String, Boolean, Number], default: "0" },
    textClass: { type: String, default: "" },
    plaintext: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    size: { type: String, default: "" },
    state: { type: [Boolean, null], default: null },
    type: { type: String, default: "text" },
    value: [String, Number],
    uppercase: { type: Boolean, default: false },
    classField: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    textFieldWidth: { type: [String, Number], default: "" },
    boldValue: { type: Boolean, default: false },
    boldLabel: { type: Boolean, default: false },
    rightAlignValue: { type: Boolean, default: false },
    marginLeftValue: { type: [String, Number], default: 0 }
  },
  computed: {
    tabIndex() {
      return this.readonly || this.plaintext ? "-1" : "0";
    }
  },
  methods: {
    updateValue(id, $event) {
      this.$emit("g3Input", { field: id, value: $event });
    },
    touchValue(id, $event) {
      this.$emit("g3Touch", { field: id, value: $event });
    }
  }
};
</script>

<style lang="css" scoped>
.form-group--compact {
  margin-bottom: .4rem;
}
.form-group--compact-readonly {
  margin-bottom: -.2rem;
}
</style>
